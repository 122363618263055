@mixin ReferenceList(
  $linkColor: inherit,
  $headingColor: $black,
  $textColor: $black,
  $oddListItemBg: transparent
) {
  .reference {
    &__list-heading {
      color: $headingColor;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 1.43;

      &__item {
        background-color: transparent;
        color: $textColor;
        padding: 10px 20px;
        margin: 0;

        &:nth-child(odd) {
          background-color: $oddListItemBg;
          border-radius: 5px;
        }

        a {
          background-repeat: repeat-x;
          background-size: 1px 2px;
          background-position: 0 1.1875rem;
          color: $linkColor;
          background-image: linear-gradient(
            to bottom,
            rgba($linkColor, 0.2) 50%,
            rgba($linkColor, 0.2) 50%
          );
        }
      }
    }
  }

  // STYLE FOR NUMBERING, ORDERED LISTS
  ol.reference__list {
    @include ReferenceCounter() {
      span {
        padding-left: 22px;
        overflow-wrap: break-word;
      }
    }
  }
}

@include site-theme(rhb) {
  @include ReferenceList(
    $headingColor: c('grey-550'),
    $textColor: c('grey-600'),
    $oddListItemBg: rgba(c('secondary'), 0.1),
    $linkColor: c('cta')
  );
}

@include site-theme(vhb) {
  @include ReferenceList(
    $headingColor: c('grey-550'),
    $textColor: c('grey-600'),
    $oddListItemBg: rgba(c('grey-600'), 0.1),
    $linkColor: c('secondary')
  );
}
