@import '../../platformui/CollapsiblePanel/styles';

@include CollapsiblePanel( $btnBoxShadow: 0 -4px 6px 0 rgba($black, 0.3), $iconContainerBg: rgba($white, 0.1), $iconContainerWidth: 40px, $iconPosition: 50%, $outline: 1px dotted $white, $outlineOffset: -1px ) {
  &__button {
    @include subheading-medium-font-family();

    @include xmedium-text($white);

    @include site-theme(rhb) {
      background: c('main-dark-alt');
    }

    @include site-theme(vhb) {
      background: c('main-dark');
    }

    &__icon-container {
      bottom: 0;

      @include breakpoint(medium only) {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        top: 11px;
      }
    }

    &__icon {
      &:before,
      &:after {
        content: '';
        background-color: $white;
        transition: transform 0.25s ease-out;
        color: $white;
      }

      &:before {
        top: -9px;
        left: 0;
        width: 2px;
        height: 16px;
        margin-left: -2px;

        @include breakpoint(medium only) {
          top: -7px;
          left: -1px;
          width: 2px;
          height: 14px;
          margin-left: 0;
        }

        .c-collapsible-panel--is-expanded & {
          transform: rotate(90deg);
        }
      }

      &:after {
        top: 0;
        left: -9px;
        width: 16px;
        height: 2px;
        margin-top: -2px;
        position: absolute;

        @include breakpoint(medium only) {
          top: -1px;
          left: -7px;
          width: 14px;
          height: 2px;
          margin-top: 0;
        }

        .c-collapsible-panel--is-expanded & {
          transform: rotate(180deg);
        }
      }
    }
  }
}