@import '../../platformui/ArticleHeader/styles';
@import '../../platformui/ArticleHeader/subject-icon';

@include site-theme(rhb, vhb) {
  @include ArticleHeader() {
    .c-articleheader {
      @include breakpoint(medium only) {
        padding-top: 35px;
      }

      &__inner-container {
        &:after {
          background: rgba(c('main'), 0.3);
          content: '';
          display: block;
          height: 5px;
          margin-bottom: 30px;
          width: 107px;

          @include breakpoint(medium) {
            margin-bottom: 38px;
          }

          .c-section & {
            display: none;
          }

          @media print {
            background: transparent;
            border-bottom: solid 5px $black;
            height: 0;
            margin-bottom: 28px;
          }
        }
      }

      &__heading {
        position: relative;
        color: c('main');
      }

      &--important {
        &:before {
          background-image: url('../../framework/images/viktigt-rhb.svg');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          content: '';
          display: block;
          height: 89px;
          margin: 16px auto 24px auto;
          opacity: 0.3;
          width: 89px;
        }
      }

      &__subject {
        color: c('cta');
        margin: -5px 0 15px 0;
        display: block;
        text-decoration: none;
        font-size: 14px;
        @include SubjectIcon($iconColor: c('cta'));

        &:hover,
        &:active {
          color: c('cta');
        }

        @include breakpoint(large) {
          display: none;
        }
      }
    }
  }
}