@mixin blockheading($border: 'true') {
  @include subheading-font-family();

  @include site-theme(umo, youmo) {
    margin-bottom: 15px;
  }

  @include site-theme(rhb) {
    margin-bottom: 25px;
  }

  @if ($border == 'true') {
    border-bottom: solid 1px rgba($black, 0.2);
    padding-bottom: 13px;

    @include site-theme(umo, youmo) {
      padding-bottom: 13px;
    }

    @include site-theme(rhb) {
      padding-bottom: 19px;
    }
  }
}

@mixin block-link($size) {
  @include icon('e807');
  border-radius: 50%;
  bottom: $size * -1;
  display: block;
  height: $size * 2;
  position: absolute;
  right: $size * -1;
  width: $size * 2;

  &:before {
    margin: 0;
    position: absolute;
    font-size: $size * 0.4;
    left: $size * 0.4;
    top: $size * 0.4;
  }

  @include breakpoint(medium) {
    bottom: ($size * -1) * 1.333;
    height: ($size * 2) * 1.333;
    right: ($size * -1) * 1.333;
    width: ($size * 2) * 1.333;

    &:before {
      font-size: ($size * 0.4) * 1.333;
      left: ($size * 0.4) * 1.333;
      top: ($size * 0.4) * 1.333;
    }
  }
}
