@import '../../platformui/HeaderTools/styles';

@include site-theme(rhb) {
  @include HeaderTools($padding: 27px 0, $iconTopLarge: -28px) {
    .c-header-tools {
      &__toolbtn {
        padding: 0;

        @include focus-handler(c('main-dark'), 2px);
        @include xmedium-text(c('main-dark'));

        &:hover {
          color: c('main-dark');
        }

        span {
          @include breakpoint(large) {
            top: -75px;
            z-index: 111;
          }
        }
      }

      &__icon {
        @include breakpoint(small only) {
          &:before {
            top: 12px;
            left: 5px;
          }
        }

        [aria-expanded='true'] & {
          background-color: $white;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          box-shadow: 0 4px 6px 0 rgba($black, 0.2);
        }

        @include breakpoint(medium) {
          [aria-expanded='true'] & {
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }

        &.c-header-tools__icon--search {
          &:before {
            background-image: url('../../framework/images/#{$globaltheme}/header-tools-search.svg');
          }
        }

        &.c-header-tools__icon--reading-aid {
          &:before {
            background-image: url('../../framework/images/#{$globaltheme}/header-tools-reading-aid.svg');
          }
        }

        &.c-header-tools__icon--region {
          &:before {
            background-image: url('../../framework/images/#{$globaltheme}/header-tools-region.svg');
          }
        }
      }
    }
  }
}

@include site-theme(vhb) {
  @include HeaderTools($padding: 54px 0 34px, $iconTopLarge: -28px) {
    .c-header-tools {
      &__toolbtn {
        padding: 0;

        @include focus-handler(c('main-dark'));
        @include xmedium-text(c('main-dark'));

        &:hover {
          color: (c(main-dark));
        }

        span {
          @include breakpoint(large) {
            top: -95px;
            z-index: 111;
          }
        }
      }

      &__icon {
        @include breakpoint(large) {
          padding: 80px 48px 60px;
          top: -55px;
        }

        &:before {
          @include breakpoint(small only) {
            top: 12px;
            left: 5px;
          }

          @include breakpoint(large) {
            top: 57px;
          }
        }

        [aria-expanded='true'] & {
          background-color: $white;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          box-shadow: 0 4px 6px 0 rgba($black, 0.2);
        }

        @include breakpoint(medium) {
          [aria-expanded='true'] & {
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }

        &.c-header-tools__icon--search {
          &:before {
            background-image: url('../../framework/images/#{$globaltheme}/header-tools-search.svg');
          }
        }

        &.c-header-tools__icon--reading-aid {
          &:before {
            background-image: url('../../framework/images/#{$globaltheme}/header-tools-reading-aid.svg');
          }
        }

        &.c-header-tools__icon--region {
          &:before {
            background-image: url('../../framework/images/#{$globaltheme}/header-tools-region.svg');
          }
        }
      }
    }
  }
}