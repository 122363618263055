@import '../../platformui/Share/styles';

@include site-theme(rhb) {
  @include Share(
    $componentMargin: 70px 0 0 0,
    //Component margin
    $componentPadding: 19px 0,
    //Component padding
    $componentBorder: solid 1px rgba(c('grey-600'), 0.4),
    //Component borders
    $iconBgColor: transparent,
    //Icon bg color
    $iconBgHoverColor: c('cta'),
    //Icon bg hover color
    $iconSocialColor: c('secondary'),
    //Icon social icon
    $headingTextTransform: uppercase //Heading transform
  ) {
    // .c-share

    &__heading {
      @include subheading-font-family();
      @include large-text(c('grey-550'));
    }
  }
}

@include site-theme(vhb) {
  @include Share(
    $componentMargin: 70px 0 0 0,
    //Component margin
    $componentPadding: 19px 0,
    //Component padding
    $componentBorder: solid 1px rgba(c('grey-600'), 0.4),
    //Component borders
    $iconBgColor: transparent,
    //Icon bg color
    $iconBgHoverColor: c('cta-dark'),
    //Icon bg hover color
    $iconSocialColor: c('secondary'),
    //Icon social icon
    $headingTextTransform: uppercase //Heading transform
  ) {
    // .c-share

    &__heading {
      @include subheading-font-family();
      @include large-text(c('grey-550'));
    }
  }
}
