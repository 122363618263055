@mixin BuildInfo($className: 'c-buildinfo') {
  .#{$className} {
    @include base-font-family();
    list-style: none;
    margin: 0;
    padding: 0;
    color: $white;
    position: fixed;
    z-index: 9999;
    width: 100%;

    &__list {
      margin: 0;
      display: flex;
      justify-content: space-between;

      li {
        margin: 0 10px 0 0;
        padding: 6px 4px 6px 0;
        display: none;
        span {
          font-weight: bold;
        }
        &.visible-mobile {
          margin: 0 10px 0 10px;
          display: block;
        }
        @include breakpoint(medium) {
          display: inline-block;
        }
      }
    }
    @content;
  }
}
