@import '../../platformui/Search/globalsearch.scss';
@import '../../platformui/SearchBlock/styles';
@import '../../platformui/Framework/platform-mixins/HeaderToolsPanel';

@include GlobalSearch() {
  @include HeaderToolsPanel( $className: c-globalsearch, $panelHeights: ( small: 80px, medium: 86px, large: 120px, ), $shadow: none ) {
    .c-globalsearch {
      z-index: 67;

      .icon-search {
        top: 2px;

        &:before {
          @include breakpoint(small only) {
            content: none;
          }

          @include breakpoint(medium only) {
            content: none;
          }
        }
      }

      &__form-component {
        padding-right: 0px;

        .c-globalsearch__field-container {
          width: calc(100% - 69px);

          @include breakpoint(medium) {
            width: calc(100% - 130px);
          }
        }

        @include breakpoint(large) {
          padding-right: 0px;
        }
      }

      &__inner {
        padding: 20px 5px;

        @include breakpoint(medium) {
          padding: 23px 20px;
        }

        @include breakpoint(large) {
          padding: 35px 0;
        }
      }
    }
  }

  @include site-theme(rhb) {
    @include SearchField( $className: c-globalsearch, $searchFieldLabel: c('secondary'), $buttonBg: c('cta'), $buttonColor: $white, $buttonBorderRadius: ( 0 10px 10px 0, 0 10px 10px 0, ), $buttonWidthLarge: 130px, $buttonHoverBg: darken(c('cta'), 5%) ) {
      .c-globalsearch__field {
        &__button {
          @include focus-handler();
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 900;
          letter-spacing: 2.5px;
          height: 40px;

          @include breakpoint(large) {
            font-size: 20px;
            height: 50px;
          }
        }
      }
    }

    @include SearchFieldQuery( $className: c-globalsearch, $searchFieldBg: rgba(c('secondary'), 0.2), $borderRadius: ( 10px 0 0 10px, 10px 0 0 10px, ), $searchFieldFocusBorder: 1px solid c('secondary'), $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(c('cta'), 0.2) ) {
      .c-globalsearch__field__query {
        font-size: 16px;
        color: c('grey-600');
        height: 40px;
        padding-left: 20px;
        border: 1px solid c('secondary');
        @include focus-handler();

        @include breakpoint(large) {
          padding-left: 56px;
          font-size: 22px;
          height: 50px;
        }

        &::placeholder {
          color: c('grey-600');
          font-style: italic;
        }
      }
    }
  }

  @include site-theme(vhb) {
    @include SearchField( $className: c-globalsearch, $searchFieldLabel: c('main'), $buttonBg: c('main'), $buttonColor: $white, $buttonBorderRadius: ( 0 10px 10px 0, 0 10px 10px 0, ), $buttonWidthLarge: 130px, $buttonHoverBg: c('main-dark') ) {
      .c-globalsearch__field {
        &__button {
          @include focus-handler();
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 900;
          letter-spacing: 2.5px;
          height: 40px;

          @include breakpoint(large) {
            font-size: 20px;
            height: 50px;
          }
        }
      }
    }

    @include SearchFieldQuery( $className: c-globalsearch, $searchFieldBg: rgba(c('main'), 0.2), $borderRadius: ( 10px 0 0 10px, 10px 0 0 10px, ), $searchFieldFocusBorder: 1px solid c('main'), $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(c('main'), 0.2) ) {
      .c-globalsearch__field__query {
        font-size: 16px;
        color: c('grey-600');
        height: 40px;
        padding-left: 20px;
        border: 1px solid c('main');
        @include focus-handler();

        @include breakpoint(large) {
          font-size: 22px;
          height: 50px;
          padding-left: 56px;
        }

        &::placeholder {
          color: c('grey-600');
          font-style: italic;
        }
      }
    }
  }
}