// ******************
// Webfont for tigrinya
// ******************
body {
  :lang(ti-ER) {
    font-family: 'Noto Sans Ethiopic';
  }
}

// ******************
// Global font mixins
// ******************
@mixin text-responsive(
  $breakpoint,
  $fontsize,
  $lineheight,
  $letterspacing: normal,
  $marginbottom: false
) {
  @if $editor == 'true' {
    @if $breakpoint == large {
      font-size: rem-calc($fontsize);
      @if $letterspacing != normal {
        letter-spacing: rem-calc($letterspacing);
      }
      line-height: $lineheight;
    }
  } @else {
    @if $breakpoint == small {
      font-size: rem-calc($fontsize);
      @if $letterspacing != normal {
        letter-spacing: rem-calc($letterspacing);
      }
      line-height: $lineheight;
    } @else {
      @include breakpoint($breakpoint) {
        font-size: rem-calc($fontsize);
        @if $letterspacing != normal {
          letter-spacing: rem-calc($letterspacing);
        }
        line-height: $lineheight;
      }
    }
  }
}

// ******************
// Headings
// ******************
@mixin heading() {
  font-weight: 900;

  & > a {
    color: inherit;
  }
}

@mixin apply-margins($breakpoint, $marginbottom: false) {
  @if $editor == 'true' {
    @if $breakpoint == large {
      @if $marginbottom != false {
        margin: 0 0 rem-calc($marginbottom);
      }
    }
  } @else {
    @if $breakpoint == small {
      @if $marginbottom != false {
        margin: 0 0 rem-calc($marginbottom);
      }
    } @else {
      @include breakpoint($breakpoint) {
        @if $marginbottom != false {
          margin: 0 0 rem-calc($marginbottom);
        }
      }
    }
  }
}

@mixin h1($applymargins: false) {
  @include text-responsive(small, 28, 1.14);
  @include text-responsive(medium, 40, 1.14);

  @if $applymargins == true {
    @include apply-margins(small, 12);
    @include apply-margins(large, 20);
  }
}

@mixin h2($applymargins: false) {
  @include text-responsive(small, 22, 1.17);
  @include text-responsive(large, 24, 1.17);

  @if $applymargins == true {
    @include apply-margins(small, 8);
    @include apply-margins(medium, 8);
    @include apply-margins(large, 10);
  }
}

@mixin h3($applymargins: false) {
  @include text-responsive(small, 18, 1.27);

  @if $applymargins == true {
    @include apply-margins(small, 6);
    @if $applymargins == true {
      @include apply-margins(large, 6);
    }
  }
}

@mixin h4($applymargins: false) {
  @include text-responsive(small, 16, 1.27);

  @if $applymargins == true {
    @include apply-margins(small, 6);
    @if $applymargins == true {
      @include apply-margins(large, 6);
    }
  }
}

// ***************
// Preamble
// ***************
@mixin lead($color: $black, $applymargins: true) {
  @include xlarge-text(c('grey-600'), 1.5);

  @if $applymargins == true {
    @include apply-margins(small, 20);
    /*@include apply-margins(medium, 20);
    @include apply-margins(large, 33);*/
  }
  font-weight: 400;

  @media print {
    font-size: 18pt;
  }

  //For tinymce based preambles
  p {
    font-size: inherit;
    line-height: inherit;
  }
}

// ***************
// Texts
// ***************
@mixin paragraph() {
  @include default();

  //Last paragraph should have more margin to next heading
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    @if $editor == 'true' {
      margin-top: rem-calc(35);
    } @else {
      margin-top: rem-calc(20);
      @include breakpoint(medium) {
        margin-top: rem-calc(15);
      }
      @include breakpoint(large) {
        margin-top: rem-calc(35);
      }
    }
  }

  @media print {
    font-size: 14pt;
  }
}

@mixin base-font-family() {
  font-family: 'Lato', sans-serif;
}

@mixin subheading-medium-font-family() {
  font-family: $site-base-font;
  font-weight: 500;
}

@mixin subheading-font-family() {
  @include site-theme(umo) {
    font-family: 'jubilat', serif;
    font-weight: normal;
  }

  @include site-theme(youmo) {
    font-weight: bold;
  }
}

@mixin meta-font-family() {
  font-family: 'proxima-nova-condensed', sans-serif;
  font-weight: bold;

  :lang(ti-ER) & {
    font-family: 'Noto Sans Ethiopic';
  }
}

@mixin meta-light-italic-font-family() {
  font-family: 'jubilat', serif;
  font-style: italic;
  font-weight: 200;

  :lang(ti-ER) & {
    font-family: 'Noto Sans Ethiopic';
  }
}

/*@mixin section-header($color:$black) {
  @include text-responsive(small, 22, 1.18);
  @include text-responsive(large, 28, 1.18);
  color:$color;
  font-weight:normal;
}*/

@mixin footer-header($color: $black) {
  @include text-responsive(small, 22, 1.18);
  @include text-responsive(large, 24, 1.18);
  color: $color;
}

@mixin topnavigation-text() {
  @include text-responsive(small, 16, 1.33);
  @include text-responsive(xlarge, 18, 1.33);
}

@mixin xxxsmall-text($color: c('grey-600'), $lineheight: 1) {
  @include text-responsive(small, 11, $lineheight);
  color: $color;
}

@mixin xxsmall-text($color: c('grey-600'), $lineheight: 1) {
  @include text-responsive(small, 12, $lineheight);
  color: $color;
}
@mixin xsmall-text($color: c('grey-600'), $line-height: 1) {
  @include text-responsive(small, 14, $line-height);
  color: $color;
}

@mixin small-text($color: c('grey-600')) {
  @include text-responsive(small, 14, 1.3);
  @include text-responsive(large, 16, 1.3);
  color: $color;
}

@mixin medium-text($color: c('grey-600')) {
  @include text-responsive(small, 16, 1.3);
  color: $color;
}

@mixin xmedium-text($color: c('grey-600'), $line-height: 1.27) {
  @include text-responsive(small, 18, $line-height);
  color: $color;
}

@mixin large-text($color: c('grey-600'), $line-height: 1.44) {
  @include text-responsive(small, 16, $line-height);
  @include text-responsive(medium, 18, $line-height);
  color: $color;
}

@mixin xlarge-text($color: $black, $line-height: 1.2) {
  @include text-responsive(small, 20, $line-height);
  @include text-responsive(large, 22, $line-height);
  color: $color;
}

@mixin xxlarge-text($color: $black) {
  @include text-responsive(small, 18, 1.4);
  @include text-responsive(large, 28, 1.4);
}

@mixin xxxlarge-text() {
  @include text-responsive(small, 30, 1.15);
  @include text-responsive(large, 40, 1.15);
}

@mixin default($color: inherit, $line-height: 1.5) {
  @include text-responsive(small, 16, $line-height);
  color: $color;
}

@mixin button-text($color: $link) {
  @include text-responsive(small, 20, 1.5);
}

@mixin meta-text() {
  @include text-responsive(small, 22, 1.3);
  @include text-responsive(large, 26, 1.3);
}

// ***************
// Focus mixins
// ***************
@mixin focus-handler($color: $black, $offset: 4px) {
  &:focus {
    @include focus-style($color, $offset);
  }
}

@mixin focus-within-handler($color: $black, $offset: 4px) {
  &:focus-within {
    @include focus-style($color, $offset);
  }
}

@mixin focus-style($color: $black, $offset: 4px) {
  outline: 2px solid $color;
  outline-offset: $offset;
  text-decoration: none;
}

// ***************
// Fancy hover
// ***************
@mixin fancy-hover($borderWidth: 35px, $borderHeight: 3px, $bottomPos: -8px) {
  & > span {
    display: inline-block;
    position: relative;

    &:after {
      border-bottom: solid $borderHeight rgba($black, 0.2);
      bottom: $bottomPos;
      content: ' ';
      display: block;
      position: absolute;
      transition: all ease 0.2s;
      width: $borderWidth;
    }
  }

  &:hover {
    text-decoration: none;

    span {
      &:after {
        width: 100%;

        @include site-theme(umo) {
          border-color: $red;
        }

        @include site-theme(youmo) {
          border-color: $red;
        }
      }
    }
  }
}

@mixin iconbase($pseudo) {
  &:#{$pseudo} {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    [dir='rtl'] & {
      margin-left: 0.2em;
      margin-right: 0;
    }

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
}

@function ununicodeIcon($character) {
  @return unquote('"') + unquote(str-insert($character, '\\', 1)) + unquote('"');
}

@mixin icon(
  $content,
  $rtl-support: true,
  $include-base: true,
  $pseudo: before
) {
  @if ($include-base == true) {
    @include iconbase($pseudo);
  }

  &:#{$pseudo} {
    content: ununicodeIcon($content);

    @if ($content == '\e808'or $content == '\e809'or $content == '\e80e') {
      [dir='rtl'] & {
        transform: scaleX(-1);
        filter: FlipH;
      }
    }

    @if $rtl-support == false {
      [dir='rtl'] & {
        transform: scaleX(1);
        filter: none;
      }
    }
  }
}

@mixin content-lists-common {
  @include paragraph();
  list-style: none;

  @if $editor == 'true' {
    margin: 0 0 rem-calc(25);
    padding-left: rem-calc(10);
  } @else {
    margin: 0 0 rem-calc(10);
    padding-left: 10px;

    [dir='rtl'] & {
      padding-right: 10px;
    }

    @include breakpoint(medium) {
      margin: 0 0 rem-calc(15);
      padding-left: rem-calc(25);
    }
  }

  li {
    @if $editor == 'true' {
      margin-bottom: rem-calc(10);
    } @else {
      margin-bottom: rem-calc(10);
    }
  }
}

@mixin ol-list() {
  list-style: decimal;
  overflow: hidden;
  @if $editor == 'true' {
    padding-left: rem-calc(32);
  } @else {
    padding-left: rem-calc(20);

    @include breakpoint(medium) {
      padding-left: rem-calc(32);
    }
  }
  li {
    padding-left: rem-calc(6);

    @include breakpoint(medium) {
      padding-left: rem-calc(10);
    }
  }

  @content;
}

@mixin ul-list($bulletColor: c('main')) {
  overflow: hidden;
  @if $editor == 'true' {
    padding-left: rem-calc(25);
  } @else {
    padding-left: rem-calc(20);

    @include breakpoint(medium) {
      padding-left: rem-calc(25);
    }
  }

  li {
    padding-left: rem-calc(6);
    position: relative;

    @include breakpoint(medium) {
      padding-left: 16px;
    }

    &:before {
      background: $bulletColor;
      border-radius: 50%;
      content: '\200B';
      display: block;
      height: rem-calc(8);
      left: 0;
      margin-left: rem-calc(-25);
      margin-right: rem-calc(15);
      position: relative;
      top: rem-calc(16);
      width: rem-calc(8);
    }
  }

  @content;
}

@mixin secondary-font-family() {
   //Do nothing as of now, needed for include in button mixin
}
