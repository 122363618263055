@mixin link-arrow(
  $color: c('main'),
  $hoverColor: $white,
  $top: 2px,
  $left: 0px,
  $fontSize: 20px,
  $bgSize: 20px,
  $bgTop: $top,
  $paddingLeft: 30px
) {
  padding-left: $paddingLeft;
  position: relative;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: $paddingLeft;
  }

  @include icon('e835');

  &:before {
    @include site-theme(rhb) {
      color: $color;
    }
    @include site-theme(vhb) {
      color: c('nav');
    }

    left: $left;
    font-size: 20px;
    position: absolute;
    top: $top;
    z-index: 2;

    [dir='rtl'] & {
      left: auto;
      right: 0px;
      transform: scaleX(-1);
    }
  }
  &:after {
    @include site-theme(rhb) {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23025802' fill-rule='nonzero' d='M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18z'/%3e%3cpath fill='%23025802' fill-rule='nonzero' d='M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z'/%3e%3cpath fill='%23FFF' d='M10.286 11.556h-4.73a1.556 1.556 0 0 1 0-3.112h4.73V6.276c0-.52.43-.943.96-.943.255 0 .5.1.68.276l3.793 3.724a.93.93 0 0 1 0 1.334l-3.794 3.724a.973.973 0 0 1-1.358 0 .934.934 0 0 1-.281-.667v-2.168z'/%3e%3c/g%3e%3c/svg%3e ");
    }
    @include site-theme(vhb) {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23007eb0' fill-rule='nonzero' d='M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18z'/%3e%3cpath fill='%23007eb0' fill-rule='nonzero' d='M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z'/%3e%3cpath fill='%23FFF' d='M10.286 11.556h-4.73a1.556 1.556 0 0 1 0-3.112h4.73V6.276c0-.52.43-.943.96-.943.255 0 .5.1.68.276l3.793 3.724a.93.93 0 0 1 0 1.334l-3.794 3.724a.973.973 0 0 1-1.358 0 .934.934 0 0 1-.281-.667v-2.168z'/%3e%3c/g%3e%3c/svg%3e ");
    }
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    display: block;
    content: '';
    top: 2px;
    left: $left;
    opacity: 0;
    position: absolute;
    z-index: 999;

    [dir='rtl'] & {
      left: auto;
      right: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      color: $hoverColor;
    }
    &:after {
      opacity: 1;
    }
  }
}
