@import '../../platformui/ImageBlock/styles';

@include site-theme(rhb) {
  @include ImageBlock(
    $textBg: rgba(c('main'), 0.3),
    $zoomRight: 0,
    $zoomBg: rgba(c('grey-700'), 0.3),
    $hoverBg: c('cta'),
    $zoomCloseBg: rgba(c('grey-700'), 0.3),
    $zoomCloseColor: $white,
    $zoomCloseFontSize: rem-calc(23)
  ) {
    .c-imageblock {
      &--is-gallery {
        @include breakpoint(large) {
          padding-top: 15px;
        }
      }

      &--main-image {
        @media print {
          margin: 0 0 46px 0;
        }
      }

      &__text {
        @include xsmall-text($black, 1.57);

        &:after {
          height: 3px;

          @media print {
            background-color: $black;
            height: 1px !important;
          }
        }
      }

      &--xsmall-bleed {
        @media print {
          margin: 0 0 46px 0 !important;
        }
      }

      &--small-bleed {
        @media print {
          margin: 0 0 46px 0 !important;
        }
      }

      &--mini-bleed {
        @media print {
          margin: 0 0 46px 0 !important;
        }
      }

      &__zoom {
        &__trigger {
          &:before {
            right: 16px;
            top: 17px;

            @include breakpoint(large) {
              right: 15px;
              font-size: 17px;

              .c-imageblock--mini-bleed &,
              .c-imageblock--mini-reverse &,
              .c-imageblock--mini & {
                top: auto;
                bottom: -34px;
                right: 5px;
              }
            }
          }

          &:after {
            top: auto;
            bottom: -45px;
            height: 30px;
            width: 30px;
            right: 10px;
            top: 10px;
            bottom: initial;

            .c-imageblock--mini-bleed &,
            .c-imageblock--mini-reverse &,
            .c-imageblock--mini & {
              top: auto;
              bottom: -40px;
              right: 0;
            }
          }

          &:hover {
            &:after {
              transform: none;
            }
          }
        }

        &__image {
          &__close {
            box-shadow: 0 2px 4px 0 c('grey-600');
            position: absolute;
            top: 9px;
            left: 9px;
          }
        }
      }
    }
  }
}

@include site-theme(vhb) {
  @include ImageBlock(
    $textBg: rgba(c('main'), 0.3),
    $zoomRight: 0,
    $zoomBg: rgba(c('grey-700'), 0.3),
    $hoverBg: c('secondary'),
    $zoomCloseBg: rgba(c('grey-700'), 0.3),
    $zoomCloseColor: $white,
    $zoomCloseFontSize: rem-calc(23)
  ) {
    .c-imageblock {
      &--is-gallery {
        @include breakpoint(large) {
          padding-top: 15px;
        }
      }

      &--main-image {
        @media print {
          margin: 0 0 46px 0;
        }
      }

      &__text {
        @include xsmall-text($black, 1.57);

        &:after {
          height: 3px;

          @media print {
            background-color: $black;
            height: 1px !important;
          }
        }
      }

      &--xsmall-bleed {
        @media print {
          margin: 0 0 46px 0 !important;
        }
      }

      &--small-bleed {
        @media print {
          margin: 0 0 46px 0 !important;
        }
      }

      &--mini-bleed {
        @media print {
          margin: 0 0 46px 0 !important;
        }
      }

      &__zoom {
        &__trigger {
          &:before {
            right: 16px;
            top: 17px;

            @include breakpoint(large) {
              right: 15px;
              font-size: 17px;

              .c-imageblock--mini-bleed &,
              .c-imageblock--mini-reverse &,
              .c-imageblock--mini & {
                top: auto;
                bottom: -34px;
                right: 5px;
              }
            }
          }

          &:after {
            top: auto;
            bottom: -45px;
            height: 30px;
            width: 30px;
            right: 10px;
            top: 10px;
            bottom: initial;

            .c-imageblock--mini-bleed &,
            .c-imageblock--mini-reverse &,
            .c-imageblock--mini & {
              top: auto;
              bottom: -40px;
              right: 0;
            }
          }

          &:hover {
            &:after {
              transform: none;
            }
          }
        }

        &__text {
          @include xsmall-text($white, 1.57);
        }

        &__image {
          &__close {
            box-shadow: 0 2px 4px 0 c('grey-600');
            position: absolute;
            top: 9px;
            left: 9px;
          }
        }
      }
    }
  }
}
