.c-link-puff {
  max-width: 250px;
  position: relative;
  left: 20%;
  padding-left: 10px;
  z-index: 2;

  &__heading {
    @include site-theme(rhb) {
      color: c('secondary');
    }
    @include site-theme(vhb) {
      color: c('main');
    }
  }

  &__content {
    margin-top: 15px;

    a {
      text-decoration: none;
      @include icon('e808');

      @include site-theme(vhb) {
        color: c('main-dark');
      }
    }
  }
}
