@import '../../platformui/SearchBlock/list.scss';
@import '../../platformui/SearchBlock/listitem.scss';

@include search-list();

@include search-list-item( $containerPadding: 14px 20px 20px 20px, $itemBgColor: $white, $itemAlternatingBgColor: rgba(c('grey-600'), 0.1), $itemBorderRadius: 5px ) {
  &__item {
    text-decoration: none;

    &:hover {
      color: c('cta');

      @include site-theme(vhb) {
        color: c('secondary');
      }
    }

    &__heading {
      text-decoration: underline;

      &:hover {
        color: c('cta-dark');

        @include site-theme(vhb) {
          color: c('cta-dark');
        }
      }
    }

    &__text {
      color: c('grey-600');
    }
  }

  &__heading {
    text-decoration: underline;
    display: inline-block;
  }

  &__text {
    @include medium-text(c('grey-600'));
  }

  @include site-theme(vhb) {
    &__heading {
      color: c('secondary');
    }
  }
}