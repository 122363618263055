@import '../../platformui/ArticleFooter/styles';

@include site-theme(rhb, vhb) {
  @include ArticleFooter($background: transparent, $padding: 0 0 30px 0) {
    .c-articlefooter {
      @include breakpoint(medium) {
        margin: 40px 0 0 0;
      }

      &__wrapper {
        @media print {
          &:first-child {
            margin-top: 20px;
          }
        }
      }

      &__key {
        margin-bottom: 5px;
        line-height: 1.27;
        @include subheading-font-family();

        @include breakpoint(small only) {
          @include large-text(c('grey-600'));
          font-weight: bold;
        }

        @media print {
          @include large-text(c('grey-600'));
          font-weight: bold;
        }
      }

      &__value {
        @include default(inherit, 1.27);
        margin-bottom: 5px;
      }

      &__content {
        overflow: hidden;

        @media print {
          overflow: visible;

          &:first-child {
            margin-top: 20px;
          }
        }
      }

      @media print {
        padding: 0 10px;
        border-top: 1px solid black;
        margin-top: 30px;

        .medium-4 {
          width: 33.3333%;
          position: relative;
        }

        .medium-8 {
          width: 66.6667%;
        }
      }
    }
  }
}
