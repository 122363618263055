@mixin Label(
  $className: 'c-forms',
  $color: black,
  $disabled: rgba(#000, 0.5),
  $required: red,
  $margin: 0 0 4px
) {
  .#{$className} {
    &__label {
      color: $color;
      display: block;
      margin: $margin;
      padding: 0;

      &--hidden {
        visibility: hidden;
      }

      &--inline {
        display: inline-block;
        float: left;
      }

      &--is-disabled {
        color: $disabled;
      }

      &__required {
        color: $required;
        display: inline-block;
        margin: 0 2px;
      }
    }

    @content;
  }
}
