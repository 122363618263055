@mixin styled-scrollbar(
  $width: rem-calc(14),
  $height: rem-calc(14),
  $border-radius: rem-calc(8),
  $track-background: transparent,
  $thumb-background: get($colors, 'main'),
  $thumb-border-color: transparent,
  $thumb-border-width: rem-calc(3),
  $track-margin: 0
) {
  //Not yet supported by W3C Validation
  // scrollbar-color: $thumb-background $track-background;
  // scrollbar-wig  dth: auto;

  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    border: $thumb-border-width solid $thumb-border-color;
    background: $thumb-background;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-track {
    margin: $track-margin;
    border-radius: $border-radius;
    background-color: $track-background;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
