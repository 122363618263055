$globaltheme:vhb;
$editor: 'false';
$globaltheme: rhb !default;

@import './base/site-globals';

@import './base/foundation-imports.scss';

@import '../../platformui/Framework/site-theme';
@import './base/color-palette';
@import './base/font-includes';
@import './base/font-base';
@import './base/font-book';
@import './base/buttons';
@import './base/reference-mixins';
@import './base/globals';
@import '../fonts/fontello/css/fontello';

@import '../../platformui/Framework/platform-mixins/CookieProPanel.scss';

@include site-theme(rhb) {
  @include CookieProPanel(
    $focusColor: $black,
    $btnBorderRadius: 10px,
    $acceptBtnTextHoverColor: $white,
    $acceptBtnHoverColor: darken(c('cta'), 5%),
    $settingsBtnTextHoverColor: darken(c('cta'), 5%),
    $settingsBtnHoverColor: rgba(0, 126, 176, 0.1),
    $settingsBtnBorder: 1px solid c('cta'),
    $btnFontSize: 16px,
    $btnFont: $site-base-font,
    $fontWeight: bold,
    $btnSize: 48px
  );
}

@include site-theme(vhb) {
  @include CookieProPanel(
    $focusColor: $black,
    $btnBorderRadius: 10px,
    $acceptBtnTextHoverColor: $white,
    $acceptBtnHoverColor: darken(c('main'), 5%),
    $settingsBtnTextHoverColor: darken(c('main'), 5%),
    $settingsBtnHoverColor: rgba(0, 126, 176, 0.1),
    $settingsBtnBorder: 1px solid c('main'),
    $btnFontSize: 16px,
    $btnFont: $site-base-font,
    $fontWeight: bold,
    $btnSize: 48px
  );
}

@import '../../platformui/Framework/blocks';
@import '../../platformui/Framework/utils';
@import '../../platformui/Linklist/styles';
@import '../../platformui/CollapsiblePanel/styles';
@import '../../platformui/ImageBlock/styles';
@import '../../platformui/Section/styles';
@import '../../platformui/Localnav/styles';
@import '../../components/build-info/styles.scss';
@import '../../components/chapter-container/styles.scss';
@import '../../platformui/Meganav/styles';
@import '../../components/video/styles';
@import '../../components/imports';
@import '../../components/zoom-container/styles.scss';
@import '../../platformui/Framework/print';
@import './base/tables.scss';
@import './base/utils';

.main,
.c-articlefooter,
.c-footer,
.c-alertlist,
.c-section--primary-intro {
  opacity: 1;
}

.main--min-height {
  min-height: 460px;
}

@include site-theme(rhb, vhb) {
  .c-header__search,
  .c-header-tools {
    display: block;
  }
}

.c-buildinfo {
  display: block;
}

.zoom-container:has(+ .reference__block-reference)>.table-overflow {
  margin-bottom: 0;
}

@media print {
  .c-header-tools,
  .c-header-toolsy {
    display: none;
  }
}
