@import '../../platformui/Footer/styles';
@import '../../platformui/Framework/platform-mixins/CookieProSettings.scss';

@include Footer() {
  .c-linklist--site-footer {
    row-gap: 30px;
    .c-linklist__link {
      display: inline-flex;
      font-size: 1rem;
    }

    @include CookieProSettings(
      $color: $white,
      $focusColor: $white,
      $fontSize: rem-calc(16px),
      $secondaryFontSize: rem-calc(16px)
    );
  }
}

@include site-theme(rhb) {
  @include Footer() {
    &__logo {
      position: static;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      &__text {
        margin-bottom: 0;
        margin-top: 0;
      }

      @include breakpoint(large) {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
      }
    }

    &__content {
      @include large-text($white);
      background: #036303;
      background-image: linear-gradient(to top, rgba(67, 146, 53, 0), c('main')),
        linear-gradient(#036303, #036303);

      @include breakpoint(large) {
        background: c('main');
      }

      &__editor {
        p {
          @include large-text($white);
        }
      }
    }

    &__site {
      background: c('main-dark-alt');

      @include breakpoint(large) {
        background: c('main-dark');
      }

      @include breakpoint(medium down) {
        .row,
        .columns {
          margin: 0;
          padding: 0;
        }
      }

      @include breakpoint(medium) {
        min-height: 68px;
      }

      &__inner {
        @include breakpoint(large) {
          padding: 20px 0;
        }
      }
    }

    &__heading {
      @include subheading-font-family();
      color: $white;
      font-size: rem-calc(20);
      margin-bottom: 0;

      @include breakpoint(large) {
        font-size: rem-calc(24);
      }
    }

    &__subheading {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 1.64;
      letter-spacing: 1px;

      @include breakpoint(medium) {
        font-size: 16px;
      }
    }

    &__collapsible-lists {
      @include small-text();

      button {
        @include focus-handler($color: $white, $offset: -3px);
      }
    }
  }
}

@include site-theme(vhb) {
  @include Footer() {
    &__logo {
      position: static;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      &__text {
        margin-bottom: 0;
        margin-top: 0;
      }

      @include breakpoint(large) {
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 0;
      }
    }

    &__content {
      @include large-text($white);
      background-color: c('main');

      &__editor {
        p {
          @include large-text($white);
        }
      }
    }

    &__site {
      background: c('main-dark');

      @include breakpoint(medium down) {
        .row,
        .columns {
          margin: 0;
          padding: 0;
        }
      }

      @include breakpoint(medium) {
        min-height: 68px;
      }

      @include CookieProSettings(
        $color: $white,
        $focusColor: $white,
        $fontSize: rem-calc(14),
        $secondaryFontSize: rem-calc(16)
      );

      &__inner {
        @include breakpoint(large) {
          padding: 20px 0;
        }
      }
    }

    &__heading {
      @include subheading-font-family();
      color: $white;
      font-size: rem-calc(20);
      margin-bottom: 0;

      @include breakpoint(large) {
        font-size: rem-calc(24);
      }
    }

    &__subheading {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.64;
      letter-spacing: 1px;

      @include breakpoint(medium) {
        font-size: 16px;
      }
    }

    &__collapsible-lists {
      @include small-text();

      button {
        @include focus-handler($color: $white, $offset: -3px);
      }
    }
  }
}
