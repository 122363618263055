@mixin RevisedAndPublished(
  $className: 'revised-and-published',
  $textColor: black,
  $revisedIcon: none,
  $newIcon: none
) {
  .#{$className} {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    @include breakpoint(large) {
      display: inline-block;
      width: 100%;
    }

    p {
      color: $textColor;
      display: inline;
      font-weight: bold;

      &:first-of-type {
        margin: 0 10px 0 20px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -24px;
          top: 3px;
          background-repeat: no-repeat;
          height: 21px;
          width: 24px;
        }

        &.revised {
          &:before {
            background-image: $revisedIcon;
          }
        }

        &.new {
          &:before {
            background-image: $newIcon;
          }
        }
      }

      span {
        font-weight: normal;
      }
    }

    @media print {
      padding-left: 20px;
    }
    @content;
  }
}
