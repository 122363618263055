.c-region-panel {
  background-color: $white;
  top: 0;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: all 0.4s ease;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  visibility: hidden;
  width: 100%;
  z-index: 11;

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 0;

    @include breakpoint(large) {
      flex-direction: row;
      padding: 30px 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__editorial {
    display: flex;
    align-items: center;
  }

  &__form {
    width: 100%;

    @include breakpoint(large) {
      width: auto;
    }
  }

  &--is-opened {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
  }

  &__dropdown {
    margin-top: 20px;
    width: 100%;
    padding-left: 0;
    position: relative;

    .c-forms__select {
      height: 40px;
      margin-bottom: 0;

      select {
        height: 40px;
        outline: none;
      }
    }

    @include breakpoint(large) {
      margin-top: 0;
      padding-left: 18px;

      .c-forms__select {
        height: 50px;
        margin-bottom: 0;

        select {
          height: 50px;
        }
      }
    }

    @include breakpoint(large) {
      width: 407px;
    }
  }

  &__selected {
    display: none;
    @include breakpoint(large) {
      display: flex;
    }

    span {
      border-right: 1px solid rgba(c('grey-600'), 0.8);
      padding-right: 30px;
      color: rgba(c('grey-600'), 0.8);
      align-self: flex-end;
      margin: 0 10px 8px 0;
    }
  }
}

.c-region-wrapper {
  display: block;
  &--selected {
    display: flex;
  }
}
