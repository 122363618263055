@import '../../platformui/Forms/styles';
@import './episerver-forms';
@include Forms();

@include Error(
  $color: c('error-dark'),
  $errorBg: c('error-light'),
  $errorBorder: solid 1px c('error-dark'),
  $errorFocusBorder: solid 1px c('error-dark'),
  $errorFocusShadow: inset 0 5px 10px 0 rgba(231, 0, 14, 0.1)
);

@include Label($color: c('grey-600'), $margin: 0 0 9px);

@include Checkbox();

@include site-theme(rhb) {
  // *** Input
  @include Input(
    $bg: rgba(c('secondary'), 0.2),
    $focusShadow: inset 0 5px 10px 0 rgba(c('secondary'), 0.2),
    $focusBorder: none,
    $borderRadius: 10px,
    $border: 1px solid c('secondary')
  ) {
    input {
      @include focus-handler();
      color: c('grey-600');

      &:focus {
        border: 1px solid c('secondary');
      }
    }
  }
  // *** Textarea
  @include Textarea(
    $bg: rgba(c('secondary'), 0.2),
    $borderRadius: 10px,
    $color: c('grey-600'),
    $focusShadow: inset 0 5px 10px 0 rgba(c('secondary'), 0.2),
    $focusBorder: none,
    $scrollBarBg: rgba(c('secondary'), 0.6),
    $scrollBarWidth: 32px,
    $scrollBarShadowWidth: 6px,
    $padding: 12px 5px 12px 20px
  ) {
    @include focus-within-handler();

    textarea:focus {
      outline: none;
    }

    input:disabled {
      opacity: 1;
      background-color: c('disabled');
      color: c('grey-500');
      border-color: c('grey-500');
    }
  }
  // *** Select
  @include Select(
    $bg: rgba(c('secondary'), 0.2),
    $borderRadius: 10px,
    $border: 1px solid c('secondary'),
    $focusBorder: 1px solid c('secondary'),
    $color: c('grey-600'),
    $focusShadow: inset 0 5px 10px 0 rgba(c('secondary'), 0.2),
    $optionHoverBg: rgba(c('secondary'), 0.6),
    $optionHoverBgDisabled: transparent,
    $scrollBarBorder: 3px solid rgba-to-rgb(rgba(c('secondary'), 0.2)),
    $scrollBarBg: rgba(c('secondary'), 0.6),
    $fadeGradient:
      linear-gradient(
        to right,
        rgba(221, 239, 247, 0),
        rgba-to-rgb(rgba(c('secondary'), 0.2))
      ),
    $fadeGradientError:
      linear-gradient(to right, rgba(254, 244, 243, 0), c('error-light'))
  ) {
    @include focus-within-handler();

    select:focus {
      outline: none;
    }

    select:disabled {
      color: c('grey-600');
      background-color: $white;
    }

    &:after {
      background: none;
    }

    &:before {
      color: c('grey-500');
    }
  }
}

// *** Input Fields - VHB *** //
@include site-theme(vhb) {
  // *** Input
  @include Input(
    $bg: rgba(c('main-light'), 0.1),
    $border: solid 1px c('main'),
    $focusShadow: inset 0 5px 10px 0 rgba(c('grey-600'), 0.2),
    $focusBorder: solid 1px rgba(c('main-light'), 0.3),
    $borderRadius: 10px
  ) {
    input {
      @include focus-handler();
      color: c('grey-600');

      &:focus {
        border: 1px solid c('main');
      }
    }
  }
  // *** Textarea
  @include Textarea(
    $bg: rgba(c('main-light'), 0.1),
    $border: solid 1px c('main'),
    $borderRadius: 10px,
    $color: c('grey-600'),
    $focusShadow: inset 0 5px 10px 0 rgba(c('grey-600'), 0.2),
    $focusBorder: solid 1px c('main'),
    $scrollBarBg: rgba(c('secondary'), 0.6),
    $scrollBarWidth: 32px,
    $scrollBarShadowWidth: 6px,
    $padding: 12px 5px 12px 20px
  ) {
    @include focus-within-handler();

    textarea:focus {
      outline: none;
    }
  }
  // *** Select
  @include Select(
    $bg: rgba(c('main-light'), 0.1),
    $border: 1px solid c('main'),
    $borderRadius: 10px,
    $color: c('grey-600'),
    $focusShadow: inset 0 5px 10px 0 rgba(c('grey-600'), 0.2),
    $focusBorder: 1px solid c('main'),
    $optionHoverBg: rgba(c('secondary'), 0.6),
    $optionHoverBgDisabled: transparent,
    $scrollBarBorder: 3px solid rgba-to-rgb(rgba(c('main-light'), 0.1)),
    $scrollBarBg: rgba(c('secondary'), 0.6),
    $fadeGradient:
      linear-gradient(
        to right,
        rgba(221, 239, 247, 0),
        rgba-to-rgb(rgba(c('main-light'), 0.1))
      ),
    $fadeGradientError:
      linear-gradient(to right, rgba(254, 244, 243, 0), c('error-light'))
  ) {
    @include focus-within-handler();

    select:focus {
      outline: none;
    }

    select:disabled {
      color: c('grey-600');
      background-color: $white;
    }

    &:after {
      background: none;
    }

    &:before {
      color: c('grey-600');
    }
  }
}
