@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include Chevron( $className: chevron--toggle-chapter, $chevronColor: c('main'), $lineWidth: 3px, $lineLength: 12px, $borderRadius: 0 0 20px 20px, $height: 50px, $top: 21px ) {
  background-color: transparent;
  right: 0;
  position: absolute;
  @include focus-handler();

  [dir='rtl'] & {
    right: unset;
    left: 0;
  }
}