@import '../../platformui/AnchorNav/styles';
@import './mixins/link-arrow';

@include site-theme(rhb) {
  @include AnchorNav(c('grey-600'), c('cta')) {
    .anchor-nav {
      &__navigation {
        border-top: 5px solid transparent;
        padding: 15px 20px 20px;
      }

      &__heading {
        color: c('grey-550');
        border-bottom: solid 5px rgba(c('grey-600'), 0.5);
      }

      &__inner {
        box-shadow: 0 0px 6px 2px rgba($black, 0.3);
      }

      &__list {
        &__link {
          &--is-selected {
            &:before {
              color: $white;
            }

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@include site-theme(vhb) {
  @include AnchorNav(c('grey-550'), c('cta')) {
    .anchor-nav {
      &__navigation {
        border-top: 5px solid transparent;
        padding: 15px 20px 20px;
      }

      &__heading {
        color: c('grey-550');
        border-bottom: solid 5px rgba(c('grey-550'), 0.5);
      }

      &__inner {
        box-shadow: 0 0px 6px 2px rgba($black, 0.3);
      }

      &__list {
        &__link {
          &--is-selected {
            &:before {
              color: $white;
            }

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}