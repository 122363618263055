@import '../Scrollbar/styles';

@mixin SurveyResult(
  $closeBtnColor: black,
  $closeBtnIcon: 'E81E',
  $resultMeterBorderColor: blue,
  $bgColor: white
) {
  .c-survey-result {
    padding-top: rem-calc(8);
    padding-left: rem-calc(16);
    padding-right: rem-calc(16);
    margin: rem-calc(7);
    background: $bgColor;
    position: relative;

    @include breakpoint(large) {
      flex: 1 0 490px;
    }

    &__text {
      margin-bottom: 20px;
    }

    &__btn {
      cursor: pointer;

      color: $closeBtnColor;
      @include icon($closeBtnIcon);
      @include focus-handler($offset: 2px);
      z-index: 2;
      top: 4px;
      right: 4px;
      position: absolute;
      font-size: 25px;
      &:before {
        margin: 0;
        font-weight: 600;
      }
    }

    &__text {
      font-size: rem-calc(16);
      margin-bottom: rem-calc(20);
      &--closed {
        font-style: italic;
        font-weight: bold;
      }
    }

    .result-item {
      padding-bottom: rem-calc(20);

      .result-label {
        text-align: left;
      }

      .result-meter {
        height: rem-calc(14);
        position: relative;
        background: white;
        border: rem-calc(1) solid $resultMeterBorderColor;
        border-radius: rem-calc(10);
        padding: rem-calc(2);
        span {
          display: block;
          height: 100%;
          border-radius: rem-calc(20);
          position: relative;
          overflow: hidden;
        }
      }
    }
  }
  @content;
}
