@mixin Regionalization(
  $className: 'c-regionalization',
  $regionPickerBgColor: rgba(c('secondary'), 0.1)
) {
  .#{$className} {
    margin-bottom: 30px;
    border-radius: 10px;
    @include site-theme(rhb) {
      border: solid 1px c('grey-500');
    }
    @include site-theme(vhb) {
      border: solid 1px c('grey-550');
    }

    &__header {
      position: relative;
    }

    &__heading {
      color: c('grey-600');
      margin-bottom: 0;
      padding: 16px 20px 15px 50px;
      position: relative;

      @include site-theme(rhb) {
        border-bottom: solid 1px c('grey-500');
        font-size: rem-calc(18);
        font-weight: 900;
        line-height: 1.22;
      }

      @include site-theme(vhb) {
        border-bottom: solid 1px c('grey-550');
        font-size: rem-calc(14);
        font-weight: normal;
        line-height: 1.43;
      }

      &:before {
        @include site-theme(rhb) {
          background-image: url('../../framework/images/rhb/region-icon.svg');
        }
        @include site-theme(vhb) {
          background-image: url('../../framework/images/vhb/region-icon.svg');
        }
        background-repeat: no-repeat;
        background-size: 30px 30px;
        content: '';
        display: block;
        height: 30px;
        left: 10px;
        position: absolute;
        top: 11px;
        width: 30px;

        @media print {
          print-color-adjust: exact; //Firefox
          -webkit-print-color-adjust: exact; //Chrome, Safari, Edge
        }
      }
      @include breakpoint(large) {
        border-bottom: none;
      }
    }

    &__toggle {
      color: c('grey-600');
      cursor: pointer;
      display: block;
      font-size: rem-calc(14);
      font-weight: normal;
      line-height: 1.43;
      padding: 16px 50px 15px 20px;
      position: relative;
      text-align: left;
      width: 100%;

      @include focus-handler();

      &:before {
        content: '';
        position: absolute;
        top: 22px;
        left: calc(100% - 28px);
        height: 11px;
        width: 2px;
        background: $black;
        transform: rotate(135deg);
        transition: transform 0.2s ease-in;
      }

      &:after {
        content: '';
        position: absolute;
        top: 22px;
        left: calc(100% - 21px);
        height: 11px;
        width: 2px;
        background: $black;
        transform: rotate(-135deg);
        transition: transform 0.2s ease-in, left 0.2s ease-in;
      }

      &.c-regionalization__toggle--is-expanded {
        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      @include breakpoint(large) {
        @include site-theme(rhb) {
          border-left: solid 1px c('grey-500');
        }
        @include site-theme(vhb) {
          border-left: solid 1px c('grey-550');
        }
        display: inline-block;
        min-width: 160px;
        max-width: 250px;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }

      &--is-expanded {
        background: rgba(c('secondary'), 0.1);
      }
    }

    &__region-picker {
      background: $regionPickerBgColor;
      height: 0;
      overflow: hidden;

      &--is-visible {
        height: auto;
        padding: 0 20px 20px;

        @include breakpoint(large) {
          @include site-theme(rhb) {
            border-top: solid 1px c('grey-500');
          }
          @include site-theme(vhb) {
            border-top: solid 1px c('grey-550');
          }
        }

        .c-regionalization__region-picker__content__inner {
          opacity: 1;
          transform: scale(1) translateY(0);
        }
      }

      &__content {
        border-top: dashed 1px rgba(c('grey-600'), 0.3);

        @include breakpoint(large) {
          border-top: none;
        }

        &__inner {
          opacity: 0;
          padding-top: 20px;
          transform: scale(0.7) translateY(40px);
          transition: all 0.25s ease;

          @include breakpoint(large) {
            columns: 3;
          }
        }
      }
    }

    &__region {
      color: c('grey-600');
      display: block;
      font-size: rem-calc(14);
      opacity: 0.5;
      overflow: hidden;
      position: relative;
      padding: 8px 0 4px 35px;
      break-inside: avoid-column;
      -webkit-column-break-inside: avoid;
      @include focus-within-handler();

      &--has-content {
        cursor: pointer;
        font-weight: bold;
        opacity: 1;
        display: inline-block;
        width: 100%;

        input[type='radio'] {
          & + span {
            cursor: pointer;
          }
        }
      }

      &--is-current {
        .c-regionalization__region__label-txt {
          @include site-theme(rhb) {
            background-image: url('../../framework/images/rhb/region-icon.svg');
          }
          @include site-theme(vhb) {
            background-image: url('../../framework/images/vhb/region-icon.svg');
          }
          background-position: right bottom 2px;
          background-repeat: no-repeat;
          background-size: 13px 13px;
          padding-right: 16px;
        }
      }
    }

    input[type='radio'] {
      position: absolute;
      left: -30px;
      top: 0;

      & + span {
        &:after {
          @include site-theme(rhb) {
            background: rgba(c('secondary'), 0.2);
            border: solid 1px rgba(c('secondary'), 0.5);
          }

          @include site-theme(vhb) {
            background: rgba(c('main-light'), 0.1);
            border: solid 1px rgba(c('main-light'), 0.3);
          }

          border-radius: 100px;
          content: '';
          display: inline-block;
          height: 20px;
          left: 0;
          position: absolute;
          top: 8px;
          width: 20px;
        }
      }

      &:checked + span {
        &:before {
          @include site-theme(rhb) {
            background: c('cta');
          }

          @include site-theme(vhb) {
            background: c('main-dark');
          }

          border-radius: 100px;
          content: '';
          display: block;
          height: 14px;
          width: 14px;
          left: 3px;
          position: absolute;
          top: 11px;
          z-index: 2;
        }
      }
    }

    &__contents {
      &:focus {
        outline: none;
      }

      &--hidden {
        display: none;
      }
    }

    &__content {
      padding: 20px 20px 0;

      &:nth-child(1) {
        @include site-theme(rhb) {
          border-top: solid 1px c('grey-500');
        }
        @include site-theme(vhb) {
          border-top: solid 1px c('grey-550');
        }
      }
    }

    @content;
  }
}

@include site-theme(rhb) {
  @include Regionalization($regionPickerBgColor: rgba(c('secondary'), 0.1));
}

@include site-theme(vhb) {
  @include Regionalization($regionPickerBgColor: rgba(c('main-light'), 0.07));
}
