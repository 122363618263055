@mixin SelfTest(
  $fontColor: black,
  $okIconUrl: none,
  $notOkIconUrl: none,
  $errorColor: red,
  $errorBgColor: orange,
  $validBgColor: #41bf6f,
  $checkedFontelloIcon: '',
  $checkboxCheckColor: black,
  $checkboxBorderColor: blue,
  $checkboxBgColor: white,
  $resultBorder: none,
  $resultBgColor: white,
  $primaryBtnTextColor: white,
  $primaryBtnBgColor: blue,
  $secondaryBtnTextColor: blue,
  $secondaryBtnBgColor: white,
  $secondaryBtnBorder: none
) {
  .c-self-test {
    border-bottom: solid 1px rgba($fontColor, 0.4);
    padding-bottom: 70px;

    @include breakpoint(large) {
      border-bottom: none;
      padding-bottom: 0;
    }

    &__question {
      margin-bottom: 30px;
    }

    &--icon {
      display: block;
      background-image: none;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
      @include breakpoint(large) {
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
      }
    }

    &--icon-ok {
      background-image: $okIconUrl;
    }

    &--icon-not-ok {
      background-image: $notOkIconUrl;
    }

    &__optionheading {
      color: $fontColor;
      font-size: 1rem;
      font-weight: bold;
      display: block;
      line-height: 1.25;
      margin-bottom: 20px;
      @include focus-handler();
      .c-self-test--icon {
        padding-right: 30px;
        @include breakpoint(large) {
          padding-right: 30px;
        }
      }
      .c-self-test__question--is-error & {
        background-color: $errorBgColor;
        border: 1px solid $errorColor;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 20px;
        padding: 10px;
        position: relative;
        width: 100%;

        @include breakpoint(large) {
          min-height: 50px;
        }
      }

      .c-self-test__question--is-valid & {
        background-color: $validBgColor;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 13px;
        padding: 5px 8px 8px 10px;
        position: relative;
        width: 100%;

        @include breakpoint(large) {
          min-height: 50px;
        }
      }

      &__text {
        margin-right: 10px;
      }
    }

    &__valign {
      .c-self-test__question--is-valid &,
      .c-self-test__question--is-error & {
        @include breakpoint(large) {
          padding-right: 50px;
          display: table-cell;
          vertical-align: middle;
        }
      }
    }

    &__label {
      color: $fontColor;
      display: block;
      margin-bottom: 9px;
      padding-left: 30px;
      position: relative;
      overflow: hidden;
      @include focus-within-handler();

      @include breakpoint(medium) {
        margin-bottom: 15px;
      }
    }

    &__checkbox {
      position: absolute;
      left: -100px;

      &:checked {
        + .c-self-test__option-text {
          @include icon($checkedFontelloIcon);
        }
      }
    }

    &__option-text {
      &:before {
        cursor: pointer;
        font-size: 12px;
        left: 6px;
        position: absolute;
        top: 6px;
        z-index: 2;
        color: $checkboxCheckColor;
      }

      &:after {
        border-radius: 4px;
        content: '';
        cursor: pointer;
        display: block;
        height: 24px;
        left: 0;
        position: absolute;
        top: 0px;
        width: 24px;
        z-index: 1;

        border: $checkboxBorderColor;
        background: $checkboxBgColor;
      }
    }

    &__result {
      border-radius: 10px;
      margin-bottom: 15px;
      background: $resultBgColor;
      border: $resultBorder;

      padding: 30px 20px 25px 20px;
      @include breakpoint(large) {
        padding: 30px;
      }

      &-heading {
        margin-top: 0;
      }
      &-info {
        margin-bottom: 50px;
      }
    }

    &__result-info {
      display: flex;
      margin-bottom: 35px;
      margin-left: 10px;
      flex-direction: column;
      @include breakpoint(large) {
        align-items: flex-start;
        flex-direction: row;
      }
      &__container {
        display: flex;
        align-items: center;
        &:first-child {
          margin-bottom: 15px;
        }
      }
      &__correct-answer {
        margin-right: 32px;
      }
      .c-self-test--icon {
        margin-right: 5px;
      }
    }

    &__result-heading {
      color: $fontColor;
      font-size: 1.125rem;
      font-weight: 900;
      line-height: 1.11;
      margin-bottom: 10px;
      @include focus-handler();
    }

    &__result-text-container {
      h2,
      h3,
      h4 {
        color: $fontColor;
        font-size: 1.125rem;
        font-weight: 900;
        line-height: 1.11;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      h3,
      h4 {
        font-size: 1rem;
      }

      p,
      ul,
      ol {
        color: $fontColor;
        font-size: 0.875rem;
        line-height: 1.43;
        margin-bottom: 10px;
      }

      &:last-child {
        p,
        ul,
        ol {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__result-text {
      color: $fontColor;
      font-size: 0.875rem;
      line-height: 1.43;
      margin-bottom: 0;
    }

    &__button {
      @extend %button !optional;
      margin: 0 10px 40px 0;
      color: $primaryBtnTextColor;
      @include focus-handler();

      background-color: $primaryBtnBgColor;

      @include breakpoint(large) {
        margin-bottom: 0;
        margin-top: 30px;
      }

      &--secondary {
        @extend %button--secondary !optional;
        color: $secondaryBtnTextColor;
        background-color: $secondaryBtnBgColor;
        border: $secondaryBtnBorder;
      }
    }

    &__imageblock {
      margin-bottom: 20px;

      .c-imageblock__text {
        margin-bottom: 40px;
      }
    }
  }
  @content;
}
