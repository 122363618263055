.is-inedit {
  body {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.u-right-col {
  margin-top: 31px;

  &--extra-margin {
    margin-top: 70px;
  }
}

.u-flex {
  display: flex;
}

.u-flexwrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &--center {
    justify-content: center;
  }

  & > [data-epi-property-name] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    & > .columns {
      margin-bottom: 10px;

      @include breakpoint(medium) {
        margin-bottom: 20px;
      }
    }
  }
  @include breakpoint(medium) {
    &--is-listview {
      column-count: 2;

      & > .columns {
        width: 50%;
      }
    }
  }
}

.u-text-center {
  text-align: center;

  &--medium {
    text-align: left;

    @include breakpoint(medium) {
      text-align: center;
    }
  }
}

.u-columns {
  column-gap: 0;
  float: none !important;
  text-align: center;

  & > .columns {
    display: inline-block;
    float: none !important;
    page-break-inside: avoid;
    vertical-align: top;
    width: 100%;

    & > .c-teaser {
      margin-bottom: 20px;
      margin-top: 5px;
      width: 100%;
    }
  }

  @include breakpoint(medium) {
    & > .columns {
      width: 33.33333%;
    }
    &.u-columns--is-listview {
      column-count: 2;
    }
    &--gt-3 {
      column-count: 3;
      column-fill: balance;
      & > .columns {
        display: flex;
        width: 100%;
      }
      &.u-columns--is-listview {
        & > .columns {
          display: inline-block;
          & > .c-teaser {
            min-height: unset;
          }
        }
      }
    }
  }

  @include breakpoint(large) {
    & > .columns {
      display: inline-block;
      width: 25%;
    }
    &--gt-3 {
      column-count: unset;
      column-fill: unset;

      // IE10+ specific style for columns to render correctly
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        column-count: auto;
        column-fill: auto;
      }

      & > .columns {
        display: inline-block;
        width: 25%;
      }
    }

    &--gt-4 {
      column-count: 4;
      & > .columns {
        display: block;
        width: 100%;
      }

      &.u-columns--is-listview {
        & > .columns {
          display: inline-block;
        }
        & > .c-teaser {
          min-height: unset;
        }
      }
    }
  }

  //Is in editmode?
  .epi-editContainer &,
  .is-inedit & {
    column-count: auto;
    column-gap: normal;
  }

  & > [data-epi-property-name] {
    column-count: 1;
    column-fill: balance;
    column-gap: 0;

    float: none !important;

    @include breakpoint(medium) {
      column-count: 3;
    }

    @include breakpoint(large) {
      column-count: 4;
    }

    & > .columns {
      display: block;
      float: none !important;
      margin-bottom: 15px;
      page-break-inside: avoid;
      width: 100%;

      & > .c-teaser {
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100%;
      }
    }
  }
}

div.row.u-columns.u-columns--is-listview > .columns {
  @include breakpoint(medium) {
    width: 50%;
  }
}

div.row.u-columns.u-columns--is-listview > .columns,
div.row.u-flexwrap.u-flexwrap--is-listview > .columns {
  & > .c-teaser {
    min-height: 20px;
    height: auto;
    border-bottom: none;
    @include breakpoint(medium) {
      border-radius: 25px;
    }
  }
}

//Fix css columns ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .u-columns {
    & > .columns {
      display: inline-block;
    }

    //Fix editmode
    & > [data-epi-property-name] {
      & > .columns {
        display: inline-block;
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  .u-columns {
    & > .columns {
      display: inline-block;
    }

    //Fix editmode
    & > [data-epi-property-name] {
      & > .columns {
        display: inline-block;
      }
    }
  }
}

.u-flex-medium {
  @include breakpoint(medium) {
    display: flex;
  }
}

.u-valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.u-valign-medium {
  @include breakpoint(medium) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.main.row {
  @include breakpoint(small only) {
    max-width: 480px;
  }
}

.u-article-main {
  margin-bottom: nth($global-article-margin-bottom, 1);

  @include breakpoint(medium) {
    margin-bottom: nth($global-article-margin-bottom, 2);
  }
  @include breakpoint(large) {
    margin-bottom: nth($global-article-margin-bottom, 3);
  }

  @media print {
    margin-bottom: 15px !important;
  }
}

// PRINT VISIBILITY CLASSES
