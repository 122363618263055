@import '../../platformui/AdjustableLinkBlock/styles';

@include AdjustableLinkBlock($minHeight: 0) {
  .c-navigationpush {
    position: relative;
    left: 15%;

    &__content {
      padding: 0;

      p {
        font-style: normal;
        font-size: 24px;

        a {
          color: c('secondary-alt');
          display: inline-block;
          font-size: inherit;
          width: 240px;
          height: 58px;
          font-weight: 900;
          padding: 0 20px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &__icon {
      float: left;
      position: absolute;
      top: 0;
      left: -20px;
      margin-top: 0;
      @include icon('e808');
    }
  }
}