@mixin Entry(
  $largeImgSize: 367px,
  $containerBg: transparent,
  $entryHeadingColor: $black,
  $textFade: transparent
) {
  .c-entry {
    border-radius: 10px;
    display: block;
    text-decoration: none;
    transform: translateY(0);
    transition: transform 0.15s ease-out, box-shadow 0.15s ease-out;

    &:hover,
    &:focus {
      box-shadow: 0 4px 8px 0 rgba(26, 26, 26, 0.2);
      outline: none;
      transform: translateY(-10px);
    }

    &__content-container {
      background-color: $containerBg;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 20px;

      @include breakpoint(medium) {
        flex-direction: row;
        height: 407px;
      }
    }

    &__img-container {
      padding-top: 100%;
      position: relative;
      width: 100%;

      //EPi On page edit
      .epi-editContainer {
        div {
          height: 100%;

          div {
            height: 100%;
          }
        }
      }

      @include breakpoint(medium) {
        margin-bottom: 0;
        padding-top: 0;
        float: left;
        height: 100%;
        width: 50%;
      }

      @include breakpoint(large) {
        height: $largeImgSize;
        width: $largeImgSize;
      }

      & > div {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .c-image {
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;

        img {
          display: none;
        }
      }
    }

    &__text-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      @include breakpoint(medium) {
        width: 50%;
      }

      @include breakpoint(large) {
        width: 58%;
      }

      &--full-width {
        width: 100%;
      }

      &__inner {
        position: relative;
        padding: 30px 0;
        height: 284px;
        overflow: hidden;
        align-self: baseline;
        margin-bottom: 20px;

        @include breakpoint(medium) {
          height: 320px;
          padding: 40px;
          margin-bottom: 0;
        }
      }

      &__heading {
        font-size: 24px;
        line-height: 1.22;
        color: $entryHeadingColor;
        margin-bottom: 10px;

        @include breakpoint(large) {
          font-size: 32px;
          margin-bottom: 20px;
        }

        &__icon {
          @include icon('\e808');

          &:before {
            position: relative;
          }
        }
      }

      &__paragraph {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 160px;
        font-size: 20px;
        color: c('grey-600');
        line-height: 1.6;
        margin-bottom: 0;
      }
    }
  }
  @content;
}

@include site-theme(rhb) {
  @include Entry(
    $containerBg: rgba(c('secondary'), 0.1),
    $entryHeadingColor: c('secondary'),
    $textFade: linear-gradient(rgba($white, 0), c('light-bg'))
  );
}

@include site-theme(vhb) {
  @include Entry(
    $containerBg: $white,
    $entryHeadingColor: c('secondary'),
    $textFade: linear-gradient(rgba($white, 0), $white)
  );
}
