@import '../Buttons/button';

@mixin PrintSidebar($backgroundColor: c('white'), $checkboxIconCode: 'e837', $radioIconCode: 'e810') {
    @media print {
        .hide-in-print,
        .print-only.hide-in-print {
            display: none;
        }
    }

    .c-printsidebar-toggle-button {
        @include focus-handler();
        background-color: c('white');
    }

    .c-printsidebar {
        height: 100vh;
        width: 405px;
        position: fixed;
        top: 0;
        right: 0;
        background-color: $backgroundColor;
        z-index: 99999;
        box-shadow: 0px 20px 20px 0px rgba($black, 0.4);
        padding: 20px;
        border-left: 1px solid c('black');
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        visibility: hidden;
        pointer-events: none;

        &.c-printsidebar__is-open {
            transform: translateX(0);
            visibility: visible;
            pointer-events: auto;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid c('black');
            padding-bottom: 20px;
            margin-bottom: 20px;

            &__heading {
                font-size: 1.3rem;
                font-weight: 700;
                color: c('black');
                margin: 0;
            }

            &__close-button {
                background: transparent;
                cursor: pointer;
                font-size: 0.75rem;
                display: flex;
                align-items: center;

                &:before {
                    margin-right: 0px;
                }

                &__icon--close {
                    @include icon($radioIconCode);
                    margin-left: 8px;
                    font-size: 1rem;
                }
            }
        }

        &__subheader {
            border-bottom: 1px solid c('black');
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        &__subheading {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 20px;
        }

        &__radio-container {
            display: flex;
            gap: 25px;

            div {
                display: flex;
                align-items: center;
                cursor: pointer;

                button {
                    @include focus-handler();
                    background-color: #f1f1f5;
                    border: 1px solid c('cta');
                    border-radius: 100%;
                    height: 20px;
                    margin-inline-end: 8px;
                    padding: 0;
                    position: relative;
                    width: 20px;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.selected {
                        &::before {
                            content: "";
                            border-radius: 100%;
                            background-color: c('cta');
                            margin: 0;
                            width: 14px;
                            height: 14px;
                            display: block;
                        }
                    }
                }

                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }
        }

        &__selection-container {
            margin-bottom: 20px;

            &__checkbox-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                border-bottom: 1px solid c('black');
                padding-bottom: 40px;
                max-height: 50vh;
                overflow-y: auto;
                margin-top: -4px;
                padding-top: 4px;
                margin-inline: -6px;
                padding-inline: 6px;

                div {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    button {
                        @include focus-handler();
                        background-color: #f1f1f5;
                        border: 1px solid c('cta');
                        border-radius: 4px;
                        height: 20px;
                        margin-inline-end: 8px;
                        padding: 0;
                        position: relative;
                        width: 20px;
                        flex-shrink: 0;

                        &.checked {
                            @include icon($checkboxIconCode);
                            font-size: 13px;
                            color: c('cta');
                        }
                    }

                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                    }
                }
            }
        }

        &__button-container {
            display: flex;
            justify-content: center;
        }

        @include button($bg: c('cta'),
            $border: solid 1px c('cta'),
            $color: c('white'),
            $hoverBg: c('cta-dark'),
            $hoverColor: c('white'),
            $className: 'c-printsidebar__button',
        ) {
            justify-self: center;
            align-self: end;
            font-weight: 700;
            padding: 14px 30px;
            width: 100%;
            height: 50px;
            display: flex;

            &__icon--print {
                @include icon('e80d');
                position: relative;
                width: 20px;

                &:before {
                    font-size: 40px;
                    position: absolute;
                    top: -9px;
                    left: -18px;
                }
            }

            @include breakpoint(large) {
                width: fit-content;
            }

            &:hover,
            &:focus {
                @include focus-handler();
            }
        }
    }
}
