@mixin button($inverted: false) {
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  font-size: rem-calc(14);
  font-weight: 900;
  letter-spacing: 1.4px;
  padding: 11px 10px 12px;
  text-transform: uppercase;

  @include site-theme(rhb) {
    background: c('secondary');
    border: solid 1px c('secondary');

    @if $inverted == true {
      background: transparent;
      color: c('secondary');
    }
  }

  @include site-theme(vhb) {
    background: c('main');
    border: solid 1px c('main');

    @if $inverted == true {
      background: transparent;
      color: c('main');
    }
  }

  &:hover,
  &:focus {
    color: $white;
    outline: none;

    @include site-theme(rhb) {
      background: c('cta');
      box-shadow: 0 3px 8px 0 rgba($black, 0.2);

      @if $inverted == true {
        background: rgba(53, 137, 146, 0.2);
        color: c('cta');
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
      }
    }

    @include site-theme(vhb) {
      background: c('main-dark');
      box-shadow: 0 3px 8px 0 rgba($black, 0.2);

      @if $inverted == true {
        color: c('main');
        background: rgba(0, 126, 176, 0.1);
      }
    }
    //@content;
  }

  @include breakpoint(medium) {
    font-size: rem-calc(20);
    padding: 13px 20px;
  }

  @content;
}

%button {
  @include button();
}

@mixin button-secondary() {
  background: transparent;

  @include site-theme(rhb) {
    color: c('secondary');

    &:hover,
    &:focus {
      background: rgba(c('secondary'), 0.2);
      box-shadow: 0 3px 8px 0 rgba($black, 0.2);
      color: c('secondary');
    }
  }

  @include site-theme(vhb) {
    color: c('main');

    &:hover,
    &:focus {
      background: rgba(c('main-light'), 0.1);
      box-shadow: 0 3px 8px 0 rgba($black, 0.2);
      color: c('main');
    }
  }
}

%button--secondary {
  @include button-secondary();
}
