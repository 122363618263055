@import '../../platformui/Alert/styles';

@include Alert( $borderRadius: 10px, $borderStyle: 1px dashed, $warningIcon: '../../framework/images/rhbvhb-warning-icon.svg', $warningBorderColor: c('warning'), $warningBackgroundColor: c('warning-light'),
// PlatformUI component require these to be set to existing images. If used in future, add correct images and update here.
$checkIcon: '../../framework/images/error.svg', $infoIcon: '../../framework/images/error.svg' ) {
  .c-alert {
    align-items: unset;
    margin-top: 30px;
    padding: 20px 0;
    opacity: 1;
    transition: opacity 0.3s ease;
    @include focus-handler();

    &__icon {
      background-size: 23px;
      height: 23px;
      margin: 0;
      width: 23px;

      @include breakpoint(large) {
        margin: 0 24px;
        background-size: 50px;
        height: 50px;
        width: 50px;
      }
    }

    &__inner {
      @include breakpoint(small only) {
        display: block;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    &__icon-container {
      align-items: unset;
      left: 0;
      margin-left: 20px;
      margin-right: 12px;
      position: relative;
      top: 2px;

      @include breakpoint(small only) {
        float: left;
        margin-left: 0px;
      }

      @include breakpoint(medium) {
        top: -5px;
      }

      @include breakpoint(large) {
        align-items: center;
        left: unset;
        top: unset;

        .contact-alert & {
          position: static;
        }
      }
    }
  }
}

;