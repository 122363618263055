@import '../../platformui/Meganav/styles';

@include Meganav( $width: $global-width, $bgColor: $white, $boxShadow: 0 3px 10px 3px rgba($black, 0.2), $itemSelectedBorderColor: $black, $itemPadding: 0 ) {
  @include site-theme(rhb, vhb) {
    padding: 50px 20px 50px 35px;

    [dir='rtl'] & {
      padding: 50px 35px 50px 20px;
    }

    &:after {
      @include site-theme(rhb) {
        background: rgba(c('secondary'), 0.1);
      }

      @include site-theme(vhb) {
        background: rgba(c('main-dark'), 0.1);
      }

      bottom: 20px;
      content: '';
      left: 50%;
      position: absolute;
      top: 20px;
      transform: translateX(-50%);
      width: 100%;
      min-height: 132px;
      border-left: 20px solid $white;
      border-right: 20px solid $white;

      [dir='rtl'] & {
        text-align: right;
      }
    }
  }

  &__container {
    &:before {
      position: absolute;
      content: '';
      width: 140%;
      min-height: 132px;
      opacity: 0.1;
      transform-origin: bottom right;
      transform: skew(-11deg, 0deg);
      left: 65%;
      top: -30px;
      bottom: -30px;

      @include site-theme(rhb) {
        background-color: c('secondary');
      }

      @include site-theme(vhb) {
        background-color: c('main-dark');
      }
    }
  }

  &__list {
    &__item {
      a {
        @include base-font-family();
        @include default($black);
        @include focus-handler();
        margin-top: 6px;

        i {
          width: 26px;
          float: left;
          @include icon('e808');

          @include site-theme(rhb) {
            color: c('cta');
          }

          @include site-theme(vhb) {
            color: c('main-dark');
          }

          &:before {
            text-decoration: none;
            margin-right: 10px;
          }
        }

        span {
          text-decoration: none;
          width: calc(100% - 26px);
          float: right;
        }
      }
    }
  }
}