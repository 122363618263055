@mixin Forms($className: 'c-forms') {
  .#{$className} {
    clear: both;
    margin-bottom: 60px;
    float: left;
    width: 100%;

    &__submit-container {
      margin-top: 20px;
      text-align: center;
    }

    @content;
  }
}

@import './error';
@import './textarea';
@import './select';
@import './input';
@import './label';
@import './radio';
@import './checkbox';
