@mixin ListViewToggler(
  $className: 'list-view-toggler',
  $textColor: #ccc,
  $muted: #000,
  $fontSize: rem-calc(16),
  $iconColor: #808285,
  $selectedIconColor: #8e0c37,
  $showToggleButtons: false,
  $btnMargin: (
    0,
    0 0 0 10px,
    0 0 0 15px,
  ),
  $btnMarginRtl: (
    0,
    0 10px 0 0,
    0 15px 0 0,
  )
) {
  .#{$className} {
    color: $textColor; /*$muted;*/
    font-size: $fontSize;

    @if ($showToggleButtons != false) {
      display: none;

      @include breakpoint(medium) {
        display: flex;
        align-items: center;
      }

      &__text {
        display: none;

        @include breakpoint(large) {
          display: inline;
        }
      }
    }

    &__btn {
      background: transparent;
      color: $iconColor;
      cursor: pointer;
      display: inline-block;
      margin: nth($btnMargin, 1);

      [dir='rtl'] & {
        margin: nth($btnMarginRtl, 1);
      }

      @include breakpoint(medium) {
        margin: nth($btnMargin, 2);

        [dir='rtl'] & {
          margin: nth($btnMarginRtl, 2);
        }
      }

      @include breakpoint(large) {
        margin: nth($btnMargin, 3);

        [dir='rtl'] & {
          margin: nth($btnMarginRtl, 3);
        }
      }

      &--is-selected {
        color: $selectedIconColor;
        outline: solid 2px $muted;
        outline-offset: 4px;
      }

      @if ($showToggleButtons != false) {
        position: relative;

        &:focus {
          outline: solid 2px $muted;
          outline-offset: 4px;
        }

        @include breakpoint(medium) {
          height: 26px;
          width: 26px;
        }

        @include breakpoint(large) {
          height: 36px;
          width: 36px;
        }

        &--brickview {
          @include icon('e85f');

          &:before {
            font-size: 26px;
            left: 0;
            position: absolute;
            top: 0;

            @include breakpoint(large) {
              font-size: 36px;
            }
          }
        }

        &--listview {
          @include icon('e85e');

          &:before {
            font-size: 26px;
            left: 0;
            position: absolute;
            top: 0;

            @include breakpoint(large) {
              font-size: 36px;
            }
          }
        }
      }
    }

    @content;
  }
}
