@import '../../platformui/ContentHistory/styles';

@include site-theme(rhb) {
  @include ContentHistory(
    $oddListItemBg: rgba(c('secondary'), 0.1),
    $linkColor: c('cta'),
    $linkHoverColor: c('cta-dark'),
    $headingColor: c('cta'),
    $headingBorderColor: c('secondary'),
    $infoPanelBorderColor: c('grey-600'),
    $startpageBg: rgba(c('secondary'), 0.1)
  );
}

@include site-theme(vhb) {
  @include ContentHistory(
    $oddListItemBg: rgba(c('grey-600'), 0.1),
    $headingBorderColor: c('grey-600'),
    $infoPanelBorderColor: c('grey-600'),
    $linkColor: c('secondary'),
    $linkHoverColor: c('cta-dark'),
    $headingColor: c('secondary')
  );
}

.content-history {
  &--start-page {
    padding: 0;
  }

  &__list {
    padding: 20px !important;
  }

  &__pagination {
    @include focus-handler();
  }

  > a {
    /* Heading link */
    height: 24px;
    margin: 20px 20px 15px;
  }

  > h3 {
    /* Header no link */
    height: 40px;
    margin: 20px 20px -5px;
  }
}
