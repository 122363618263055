@import '../../platformui/Linklist/styles';

@include Linklist() {
  .c-linklist {
    @include Linklist--block();

    a {
      &:before {
        top: 3px;
      }

      @include site-theme(vhb) {
        color: c('secondary');
      }
    }

    &--white {
      a {
        @include focus-handler($white);
        color: $white;
        line-height: 1.3;
      }
    }

    &--site-footer {
      color: $white;
      display: flex;
      flex-direction: column;
      width: 100%;

      @include breakpoint(large) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .c-linklist {
        width: fit-content;
      }

      li {
        @include breakpoint(medium down) {
          margin: 0 !important;
          background-color: c('main-dark-alt');
          box-shadow: 0 -4px 6px 0 rgba($black, 0.3);
          display: block;
          padding: 10px 0 10px 20px;
          width: 100%;

          @include site-theme(vhb) {
            background-color: c('main-dark');
          }

          box-shadow: none;
        }
      }

      a {
        @include focus-handler($white);
        text-decoration: underline;
        font-size: rem-calc(14);
        color: $white;

        @include breakpoint(large) {
          font-size: rem-calc(16);
        }

        @include breakpoint(medium down) {
          display: block;
          width: 100%;
        }

        &:before {
          top: 1px;
        }
      }
    }

    &--collapsible {
      a {
        @include focus-handler($black, 0);
        color: $black;
        font-size: 17px;
      }

      .c-linklist__header {
        @include large-text($white);
      }
    }

    &--inline {
      li {
        margin: 0 20px 5px 0;

        [dir='rtl'] & {
          margin: 0 0 5px 20px;
        }
      }
    }

    &--block {
      .c-linklist__header {
        @include blockheading();
        @include large-text(c('grey-550'));
      }
    }

    &__link {
      &:hover {
        @include site-theme(rhb) {
          &:before,
          &:after,
          &:active {
            .c-linklist--block & {
              color: c('cta');
            }
          }
        }

        @include site-theme(vhb) {
          &:before,
          &:after,
          &:active {
            .c-linklist--block & {
              color: c('secondary-dark');
            }
          }
        }
      }

      &:before {
        @include site-theme(rhb) {
          .c-linklist--block & {
            color: c('secondary');
          }
        }
      }
      //Types of icons
      //Default external

      @include icon('e808');

      &:after {
        font-size: 15px;
        margin-left: 6px;

        @include site-theme(rhb) {
          .c-linklist--block & {
            color: c('secondary');
          }
        }
      }

      .c-linklist--no-icons & {
        padding-left: 22px;
      }

      .c-linklist--collapsible,
      .c-linklist--no-icons & {
        padding-left: 25px;
      }

      &[href*='.rikshandboken-bhv.se'],
      &[href*='.rhb.local'],
      &[href*='.vardhandboken.se'],
      &[href*='.vhb.local'],
      &[href*='localhost'],
      &[href^='/'],
      &[href^='/link/'],
      &[href^='#'] {
        @include icon('e808', true, false);

        &:after {
          display: none;
        }

        .c-linklist--no-icons & {
          padding-left: 0;

          &:before {
            content: '';
            display: none;
          }
        }

        .c-linklist--collapsible & {
          padding-left: 0px;
        }
      }

      @media print {
        &:before,
        &::after {
          content: none !important;
        }
      }

      &[href*='facebook.com'],
      &[href*='facebook.se'] {
        @include icon('e80a', false, false);

        &:after {
          display: none;
        }
      }

      &[href*='youtube.com'] {
        @include site-theme(rhb) {
          &:after {
            display: none;
          }
        }
      }

      &[href^='mailto:'] {
        @include site-theme(rhb) {
          &:after {
            display: none;
          }
        }
      }

      &[href*='instagram.com'],
      &[href*='instagram.se'] {
        @include site-theme(rhb) {
          &:after {
            display: none;
          }
        }
      }
      //overrides
      .c-linklist--icon-arrow-small & {
        @include icon('e807', true, false);
        padding-left: 28px;

        &:before {
          font-size: 120%;
          top: 3px;
          position: absolute;
        }
      }

      .u-content-inline-lists & {
        span {
          text-decoration: underline;

          &:hover {
            color: c('cta-dark');
          }
        }
      }
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      &__list {
        // Fix for IE, https://stackoverflow.com/questions/6018611/smallest-data-uri-image-possible-for-a-transparent-image
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7) !important;
      }
    }
  }
}
