@import '../../platformui/ReadingAid/styles';

@include ReadingAid( $backgroundColor: $white, $activateButtonBg: c('secondary-alt'), $bottomBorderRadius: false, $balloon-tip: false, $padding: ( small: 20px, large: 33px 0, ), $maxWidth: 100% ) {
  &--is-opened {
    filter: none;
  }

  &__inner {
    color: c('grey-600');
    font-size: rem-calc(16);

    @include breakpoint(medium) {
      font-size: rem-calc(20);
    }

    p {
      font-size: inherit;
      line-height: 1.6;
    }

    a {
      @include site-theme(vhb) {
        color: c('secondary');
      }

      @include site-theme(rhb) {
        color: c('cta');
      }
    }

    > div {
      margin-right: 10px;
    }
  }

  &__activatebtn {
    @extend %button;
    @include focus-handler();

    @include site-theme(rhb) {
      background-color: c('cta');

      &:hover {
        background-color: darken(c('cta'), 5%);
      }
    }

    font-size: rem-calc(14);
    height: 40px;
    width: 250px !important;

    @include breakpoint(medium) {
      font-size: rem-calc(18);
      height: 50px;
      width: 340px !important;
    }
  }
}