@import '../../platformui/RevisedAndPublished/styles.scss';

@include RevisedAndPublished( $textColor: c('grey-600'), $revisedIcon: url('../../framework/images/revised-icon.svg'), $newIcon: url('../../framework/images/new-icon.svg') ) {
    @include breakpoint(large) {
        display: flex;
        flex-direction: row;
        width: 100%;

        p {
            margin-bottom: 0;
        }
    }
};