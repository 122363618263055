@mixin HeaderTools($padding: 35px 0, $iconTopLarge: -35px) {
  .c-header-tools {
    float: right;
    list-style: none;
    margin: 0 36px 0 0;
    padding: 0;
    z-index: 10;

    @include breakpoint(medium) {
      margin: 31px 50px 0 0;
    }

    @include breakpoint(large) {
      display: none;
      margin: 0;
      padding: $padding;
    }

    li {
      display: block;
      float: left;
      height: 48px;
      margin: 0;

      @include breakpoint(large) {
        height: 80px;
      }

      &.show-for-large {
        display: none;

        @include breakpoint(large) {
          display: block;
        }
      }
    }

    &__toolbtn {
      cursor: pointer;
      display: block;
      padding: 0;
      text-decoration: none;
      text-align: center;

      &:hover {
        text-decoration: none;
      }

      @include breakpoint(medium) {
        height: 50px;
      }

      @include breakpoint(large) {
        height: 80px;
        position: relative;
      }

      span {
        display: none;
        text-align: center;

        @include breakpoint(large) {
          //unset show for sr
          clip: unset;
          clip-path: none;
          display: block;
          height: auto;
          overflow: visible;
          position: relative !important;
          width: auto;
          top: -34px;
        }
      }
    }

    &__icon {
      position: relative;
      display: inline-block;
      padding: 24px 18px;
      z-index: 66;
      top: 0;

      @include breakpoint(medium) {
        top: -37px;
        padding: 42px 30px;
      }

      @include breakpoint(large) {
        top: $iconTopLarge;
        padding: 60px 48px;
      }

      &:before {
        content: '';
        background-repeat: no-repeat;
        position: absolute;
        height: 25px;
        width: 25px;
        transition: transform 0.2s ease;
        left: 7px;
        top: 14px;
        background-size: 100%;

        @include breakpoint(medium) {
          height: 35px;
          width: 35px;
          left: 12px;
          top: 37px;
        }

        @include breakpoint(large) {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      @include breakpoint(large) {
        &:hover:before {
          transform: scale(1.3) translateX(-40%);
        }
      }
    }
  }

  @content;
}
