@import '../../platformui/AlertList/styles';
@import '../../platformui/StatusMessage/styles.scss';

@include AlertList() {
  @include StatusMessage( $icon: '../../framework/images/alert.svg', $bgColor: c('warning-light') ) {
    @include breakpoint(large) {
      .statusmessage__expand-button .statusmessage__expand-button__icon {
        top: 0px;
      }

      .statusmessage__heading {
        padding-left: 10px;
      }

      .statusmessage__expand-button {
        margin-top: 6px;
      }
    }
  }
}