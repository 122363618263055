@import '../../platformui/SearchBlock/styles';

.c-search {
  .c-alert {
    p {
      padding: 0;
    }
  }
}

.u-search-page {
  .c-articleheader__inner-container::after {
    content: none;
  }
}

@include site-theme(rhb) {
  @include SearchList() {
    &__heading {
      font-size: rem-calc(18);
      @include default();
    }
  }

  @include SearchField( $searchFieldLabel: c('secondary'), $buttonBg: c('cta'), $buttonColor: $white, $buttonBorderRadius: ( 0 10px 10px 0, 0 10px 10px 0, ), $buttonWidthLarge: 130px, $buttonHoverBg: darken(c('cta'), 5%) ) {
    .c-search__field {
      &__button {
        @include focus-handler();
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 2.5px;

        @include breakpoint(large) {
          font-size: 25px;
        }
      }
    }

    @include SearchFieldQuery( $searchFieldBg: rgba(c('secondary'), 0.2), $borderRadius: ( 10px 0 0 10px, 10px 0 0 10px, ), $searchFieldFocusBorder: 1px solid c('secondary'), $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(c('cta'), 0.2) ) {
      .c-search__field__query {
        @include subheading-font-family();

        @include breakpoint(medium) {
          @include xlarge-text(c('grey-600'));
        }

        @include focus-handler();
        color: c('grey-600');
        border: 1px solid c('secondary');

        &::placeholder {
          color: c('grey-600');
          font-style: italic;
        }
      }
    }

    @include SearchAutocomplete( $panelBg: c('autocomplete-bg'), $panelPadding: 0, $itemHoverBg: rgba($black, 0.1), $bottomBorderRadius: 10px, $topBorderRadius: 10px, $itemFontStyle: normal, $itemPadding: 6px 20px 10px 22px ) {
      box-shadow: 2px 2px 6px 0 rgba($black, 0.15);
      margin-top: 4px;
      min-width: 100%;
      left: 0;
      right: auto;

      @include breakpoint(medium) {
        left: 0;
        right: auto;
      }

      @include breakpoint(large) {
        left: 35px;
        margin-top: -10px;
        max-width: calc(100% - 166px);
        min-width: 328px;
        right: auto;
      }

      &__item {
        a {
          color: c('grey-600');
          font-size: rem-calc(16);
          line-height: 1.64;

          @include breakpoint(large) {
            font-size: rem-calc(22);
          }
        }
      }
    }

    @include SearchTools() {
      .c-search__tools {
        .c-searchlist__heading {
          @include medium-text();

          @include breakpoint(medium) {
            @include large-text();
          }
        }
      }
    }
  }
}

@include site-theme(vhb) {
  @include SearchList() {
    &__heading {
      font-size: rem-calc(18);
      @include default();
    }
  }

  @include SearchField( $searchFieldLabel: c('main'), $buttonBg: c('main'), $buttonColor: $white, $buttonBorderRadius: ( 0 10px 10px 0, 0 10px 10px 0, ), $buttonWidthLarge: 130px, $buttonHoverBg: darken(c('main'), 5%) ) {
    .c-search__field {
      &__button {
        @include focus-handler();
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 2.5px;

        @include breakpoint(large) {
          font-size: 25px;
        }
      }
    }

    @include SearchFieldQuery( $searchFieldBg: rgba(c('main'), 0.2), $borderRadius: ( 10px 0 0 10px, 10px 0 0 10px, ), $searchFieldFocusBorder: 1px solid c('main'), $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(c('main'), 0.2) ) {
      .c-search__field__query {
        @include subheading-font-family();

        @include breakpoint(medium) {
          @include xlarge-text(c('grey-600'));
        }

        color: c('grey-600');
        border: 1px solid c('main');
        @include focus-handler();

        &::placeholder {
          color: c('grey-600');
          font-style: italic;
        }
      }
    }

    @include SearchAutocomplete( $panelBg: c('autocomplete-bg'), $panelPadding: 0, $itemHoverBg: rgba($black, 0.1), $bottomBorderRadius: 10px, $topBorderRadius: 10px, $itemFontStyle: normal, $itemPadding: 6px 20px 10px 22px ) {
      box-shadow: 2px 2px 6px 0 rgba($black, 0.15);
      margin-top: 4px;
      min-width: 100%;
      left: 0;
      right: auto;

      @include breakpoint(medium) {
        left: 0;
        right: auto;
      }

      @include breakpoint(large) {
        left: 35px;
        margin-top: -10px;
        max-width: calc(100% - 166px);
        min-width: 328px;
        right: auto;
      }

      &__item {
        a {
          color: c('grey-600');
          font-size: rem-calc(16);
          line-height: 1.64;

          @include breakpoint(large) {
            font-size: rem-calc(22);
          }
        }
      }
    }

    @include SearchTools() {
      .c-search__tools {
        .c-searchlist__heading {
          @include medium-text();

          @include breakpoint(medium) {
            @include large-text();
          }
        }
      }
    }
  }
}

.c-search__field {
  padding-right: 0;

  &-container {
    width: calc(100% - 69px);

    @include breakpoint(medium) {
      width: calc(100% - 130px);
    }
  }
}