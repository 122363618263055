@charset "utf-8";
/******************
* Jubilat versions
*******************/
//Prefetch lato Regular!
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'),
    url('../../fonts/lato/lato-v22-latin-regular.woff2') format('woff2'),
    url('../../fonts/lato/lato-v22-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato Italic'),
    url('../../fonts/lato/lato-v22-latin-italic.woff2') format('woff2'),
    url('../../fonts/lato/lato-v22-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'),
    url('../../fonts/lato/lato-v22-latin-700.woff2') format('woff2'),
    url('../../fonts/lato/lato-v22-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Extra Bold'),
    url('../../fonts/lato/lato-v22-latin-900.woff2') format('woff2'),
    url('../../fonts/lato/lato-v22-latin-900.woff') format('woff');
}

//Reset font include paths
@font-face {
  font-family: 'fontello';
  font-display: swap;
  src: url('../../fonts/fontello/font/fontello.woff2') format('woff2'),
    url('../../fonts/fontello/font/fontello.woff') format('woff'),
    url('../../fonts/fontello/font/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*****************
* Fontello
*****************/
@import '../../fonts/fontello/css/fontello';
