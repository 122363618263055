@import '../../platformui/ArticleList/styles';

@include site-theme(rhb) {
  @include ArticleList(
    $headingColor: c('secondary'),
    $vignetteColor: c('grey-600'),
    $textColor: c('grey-600'),
    $borderBottom: solid 5px transparent,
    $borderBottomHover: solid 5px rgba(c('secondary'), 0.3),
    $lineClamp: 4,
    $textHeight: 100%,
    $mobileLineClamp: 5,
    $mobileTextHeight: 120px,
    $contentHeight--gt2: 100%,
    $contentHeight--gt3: 100%,
    $contentHeight--nth2: 100%,
    $maxHeightContentSmall: 100%,
    $linkIcon: 'E808',
    $linkIconColor: c('secondary')
  ) {
    //.c-article-list

    &__link {
      &:focus,
      &:hover {
        &:before {
          color: c('cta-dark');
        }
      }
    }

    &__heading {
      font-weight: 900;
      @include focus-handler($offset: 0px);
    }

    &__content {
      padding-bottom: 10px;
    }

    &__vignette {
      @include xxxsmall-text(c('grey-600'));
      @include base-font-family();
    }

    &__text {
      position: relative;
    }
    //Only one article in list
    &--full-layout {
      .c-article-list__content-wrapper {
        &:after {
          bottom: 0;
        }
      }

      .c-article-list__img-container {
        margin-bottom: 30px;

        @include breakpoint(medium) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include site-theme(vhb) {
  @include ArticleList(
    $headingColor: c('secondary'),
    $vignetteColor: c('grey-600'),
    $textColor: c('grey-600'),
    $borderBottom: solid 5px transparent,
    $borderBottomHover: solid 5px rgba(c('secondary'), 0.3),
    $lineClamp: 4,
    $textHeight: 100%,
    $mobileLineClamp: 5,
    $mobileTextHeight: 100%,
    $contentHeight--gt2: 100%,
    $contentHeight--gt3: 100%,
    $contentHeight--nth2: 100%,
    $maxHeightContentSmall: 100%,
    $linkIcon: 'E808',
    $linkIconColor: c('secondary')
  ) {
    //.c-article-list

    &__heading {
      font-weight: 900;
      @include focus-handler($offset: 0px);
    }

    &__content {
      padding-bottom: 10px;
    }

    &__vignette {
      @include xxxsmall-text(c('grey-600'));
      @include base-font-family();
    }

    &__text {
      position: relative;
    }

    &__link {
      &:hover,
      &:focus {
        &:before {
          color: c('cta-dark');
        }
      }
    }

    //Only one article in list
    &--full-layout {
      .c-article-list__content-wrapper {
        &:after {
          //background-image: none;
          bottom: 0;

          @include breakpoint(large) {
            bottom: 57px;
          }

          @include breakpoint(1215px) {
            bottom: 70px;
          }
        }
      }

      .c-article-list__img-container {
        margin-bottom: 30px;

        @include breakpoint(medium) {
          margin-bottom: 0;
        }
      }
    }
  }
}
