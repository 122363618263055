@import '../../platformui/Pagination/styles';

@include pagination() {
  @extend %button;
  @extend %button--secondary;
  @include focus-handler();

  @include site-theme(rhb) {
    color: c('cta');

    &:hover {
      color: c('cta');
    }
  }

  @include site-theme(vhb) {
    border-color: c('main');
    color: c('main-dark');

    &:hover {
      color: c('main-dark');
    }
  }

  &__text {
    .content-history__list__footer &,
    .c-searchlist__footer & {
      background-image: none;

      &:hover,
      &:focus,
      &:active {
        background-image: none;
      }
    }
  }
}