@import '../../platformui/Mobilenav/styles';

@include site-theme(rhb) {
  @include MobileNavWrapper($bgColor: c('main')); // main list background color

  @include MobileNav( $expandButtonBg: rgba(c('cta'), 0.03), $isSelectedBorderLeft: c('cta') ) {
    @include ExpandButton($bgColorBtn: rgba($white, 0.07)) {
      @include focus-handler($color: $white, $offset: -3px);
    }
    // button style, first level

    a {
      @include focus-handler($color: $white, $offset: -3px);
    }
  }

  @include MobileNavSubLevel( $borderTopColor: rgba(72, 72, 72, 0.2), $listItemBorderLeftColor: rgba(c('cta'), 0.1), $isOpenBg: rgba(c('cta'), 0.07), $borderOnHoverColor: c('cta') ) {
    a {
      @include focus-handler($offset: -3px);
    }

    @include ExpandButton( $plusMinusColor: c('secondary'), $bgColorBtn: rgba(c('cta'), 0.1) ) {
      @include focus-handler($offset: -3px);
    }
    // button style, sub levels
    @include SubLevelIndent($textIndent: 30px) {
      // sublevel 2
      @include SubLevelIndent($textIndent: 60px) {
        // sublevel 3
        // prettier-ignore
        @include SubLevelIndent($textIndent: 80px) // sublevel 4
      }
    }
  }
}

@include site-theme(vhb) {
  @include MobileNavWrapper($bgColor: c('main')); // main list background color

  @include MobileNav( $expandButtonBg: rgba(c('main'), 0.03), $isSelectedBorderLeft: c('main') ) {
    @include ExpandButton($bgColorBtn: rgba($white, 0.07)) {
      @include focus-handler($color: $white, $offset: -2px);
    }
    // button style, first level

    a {
      @include focus-handler($color: $white, $offset: -2px);
    }
  }

  @include MobileNavSubLevel( $borderTopColor: rgba(72, 72, 72, 0.2), $listItemBorderLeftColor: rgba(c('main'), 0.1), $isOpenBg: rgba(c('main'), 0.07), $borderOnHoverColor: c('main') ) {
    a {
      @include focus-handler($offset: -2px);
    }

    @include ExpandButton( $plusMinusColor: c('main'), $bgColorBtn: rgba(c('main'), 0.1) ) {
      @include focus-handler($offset: -2px);
    }
    // button style, sub levels
    @include SubLevelIndent($textIndent: 30px) {
      // sublevel 2
      @include SubLevelIndent($textIndent: 60px) {
        // sublevel 3
        // prettier-ignore
        @include SubLevelIndent($textIndent: 80px) // sublevel 4
      }
    }
  }
}