@import '../../platformui/ImageGallery/styles';

@include ImageGallery( $background: transparent, $icon: 'f105', $iconSizeSmall: 25px, $iconPositionSmall: -28px, $iconSizeMedium: 40px, $iconPositionMedium: -12px, $currentBgOpacity: 0.1, $currentFontWeight: normal ) {
  padding: 0 10px;

  @include breakpoint(large) {
    overflow: visible;

    &__content,
    &__items-container {
      overflow: visible;
    }
  }

  &__ctrls {
    &--no-images {
      padding: 0;
      top: 45%;
    }
  }

  &__current {
    color: c('grey-600');
    font-size: 14px;
  }

  &__current-container {
    bottom: 20px;
    padding: 0 20px;
    position: absolute;

    @include breakpoint(medium) {
      bottom: 40px;
      margin-top: 0;
      padding: 0 60px;
    }
  }

  &__heading {
    padding: 0 10px;

    @include breakpoint(medium) {
      padding: unset;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0 0 63px;

    @include breakpoint(medium) {
      padding: 0 0 83px;
    }

    &--no-images {
      @include breakpoint(medium) {
        padding: 20px 30px 30px 30px;
      }

      @include breakpoint(large) {
        padding: 30px 0;
      }
    }

    &__illustrator {
      align-self: flex-start;
      bottom: 0;
      color: c('grey-600');
      font-size: rem-calc(14);
      margin: auto 0 0;
      max-width: 100%;
      padding: 0 20px;
      position: relative;
      text-transform: capitalize;

      @include breakpoint(medium) {
        align-self: flex-end;
        margin-bottom: -38px;
        padding: 0 60px 0 170px;
      }
    }

    &__heading {
      color: c('grey-700');
      padding: 0 20px;

      @include breakpoint(medium) {
        padding: 0 60px;
      }
    }
    /*TODO: Fixa detta i plattformen */
    &__text-wrapper {
      margin: 0;
      padding: 0;
    }

    &__text-container {
      padding: 0 20px;

      @include breakpoint(medium) {
        padding: 0 60px;
      }
    }
  }

  &__nextprev {
    @include breakpoint(medium) {
      overflow: hidden;
    }

    @include breakpoint(small only) {
      &:before {
        right: -8px;

        [dir='rtl'] & {
          left: -8px;
        }
      }
    }

    @include breakpoint(large) {
      height: 80px;
      top: calc(50% - 40px);
      width: 40px;

      &:hover,
      &:focus {
        &:after {
          @include site-theme(rhb) {
            background: rgba($black, 0.5);
          }

          @include site-theme(vhb) {
            background: c('secondary');
          }
        }
      }
    }
  }

  &__wrapper {
    border: 1px solid rgba(c('grey-600'), 0.3);
    border-radius: 10px;
    padding: 10px;

    @include breakpoint(medium) {
      padding: 0;
    }

    @media print {
      border: none;
    }
  }
}