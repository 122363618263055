.c-action-table {
  .table-wrapper-inner {
    padding-top: 2px;

    @include breakpoint(large) {
      margin-left: -33%;
    }

    &--border-top {
      border-top: 2px solid rgba(c('grey-600'), 0.2);
    }

    @media print {
      margin-left: 0;
    }
  }

  @media print {
    display: table;
    position: relative;
    overflow: auto;
  }

  &__table {
    margin-bottom: 40px;
    min-width: 100%;
    display: block;

    @include breakpoint(large) {
      display: table;

      /* IE10+ specific style */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
      }
    }

    @media print {
      page-break-inside: auto;
      display: table;
    }
  }

  &__caption {
    background: rgba(c('grey-600'), 0.05);
    color: c('grey-600');
    font-size: 1.125rem;
    font-weight: normal;
    letter-spacing: 2px;
    padding: 14px 20px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    display: block;
    margin-bottom: 2px;

    @include breakpoint(large) {
      display: table-caption;

      /* IE10+ specific style */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
      }
    }
  }

  &__table-footer {
    position: relative;
    padding-bottom: 1px;

    p {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &:after {
      background: rgba(c('secondary'), 0.3);
      bottom: 0;
      content: '';
      left: 0;
      height: 2px;
      position: absolute;
      width: 87px;
      padding-bottom: 1px;
    }
  }

  &__column-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include breakpoint(large) {
      flex-direction: row;

      @media print {
        //flex-direction: column;
        border: 1px solid #1a1a1a;
        border-radius: 10px;
        display: table;
      }
    }
  }

  &__secondary-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
      flex-wrap: wrap;
      width: 66.66666666666666%;
    }

    @media print {
      width: 100% !important;
      display: table;
    }
  }

  &__large-headings {
    display: none;

    @include breakpoint(large) {
      align-items: center;
      background-color: #b4d3ae;
      display: flex;
      height: 50px;
      padding-left: 20px;

      p {
        color: c('grey-600');
        font-size: 18px;
        font-weight: bold;
        line-height: 1.06;
        margin-bottom: 0;
        padding: 13px 20px;
        text-align: left;
        white-space: pre-wrap;

        &:first-child {
          width: 33.33333333%;
        }

        &:last-child {
          text-align: center;
          width: 66.6666666%;
        }
      }
    }

    @media print {
      display: none;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;

    @include breakpoint(large) {
      flex-direction: row;
      width: 33.333333333%;
      border-right: 2px solid $white;

      .c-action-table__secondary-wrapper & {
        width: 50%;

        &:last-child {
          border: none;
        }
      }
    }

    @media print {
      width: 100% !important;
      flex-direction: column !important;

      page-break-inside: auto;
      display: table;
    }

    &__heading {
      background-color: #b4d3ae;
      color: c('grey-600');
      font-weight: bold;
      font-size: 18px;
      padding: 13px 20px;
      line-height: 1.06;
      text-align: left;
      white-space: pre-wrap;
      margin-bottom: 0;

      @include breakpoint(large) {
        display: none;
        font-size: 16px;
        height: 62px;
      }

      [dir='rtl'] & {
        text-align: right;
      }

      &--large-only {
        display: none;

        @include breakpoint(large) {
          display: block;
          width: 100%;
        }
      }

      @media print {
        display: block;
        background-color: rgba($black, 0.3);
        border-bottom: 1px solid #1a1a1a;
        width: 100%;
      }
      .c-action-table__secondary-wrapper & {
        @media print {
          border-top: 1px solid #1a1a1a;
        }
      }
    }

    &__content {
      padding: 20px;
      width: 100%;

      $opacities: (
        first: 0.2,
        second: 0.25,
        third: 0.3,
      );
      @each $number, $opacity in $opacities {
        &--#{$number} {
          background-color: rgba(c('main'), $opacity);
        }
      }

      @media print {
        padding: 10px 20px;
        background-color: transparent;
      }
    }
  }

  hr {
    border: none;
    background-image: linear-gradient(
      to right,
      rgba(c('main'), 0.3) 70%,
      rgba(c('main'), 0) 20%
    );
    background-position: left;
    height: 2px;
    background-size: 26px 2px;
    background-repeat: repeat-x;
    margin-bottom: 17px;
  }
}
