@import '../../platformui/SearchLinkList/styles';
@import '../../platformui/SearchLinkList/item-list.scss';
@import '../../platformui/SearchLinkList/item.scss';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include site-theme(rhb) {
  @include SearchLinkList( $borderColor: c('grey-550'), $headingColor: c('grey-550') ) {
    &__btn {
      @include focus-handler();
    }
  }

  .search-link-list:last-child {
    border-bottom: 1px solid c('grey-550');
  }

  @include LinkItemList($borderColor: c('cta'), $spanColor: c('cta')) {
    &__ctrls {
      padding-left: 10px;

      .c-pagination {
        @include focus-handler();
      }

      @include breakpoint(large) {
        padding: unset;
      }
    }

    @include breakpoint(large) {
      &__link {
        padding-right: 5px;
      }
    }
  }

  @include SearchLinkItem( $borderColor: c('cta'), $oddBgColor: c('light-bg'), $color: c('cta') ) {
    margin: 0;

    &__link {
      padding-left: 0;

      @include breakpoint(large) {
        padding-left: 15px;
      }
    }
  }
}

@include site-theme(vhb) {
  @include SearchLinkList( $borderColor: c('grey-550'), $headingColor: c('grey-550') ) {
    &__btn {
      @include focus-handler();
    }
  }

  .search-link-list:last-child {
    border-bottom: 1px solid c('grey-550');
  }

  @include LinkItemList( $borderColor: c('secondary'), $spanColor: c('secondary') ) {
    &__ctrls {
      padding-left: 10px;

      .c-pagination {
        @include focus-handler();
      }

      @include breakpoint(large) {
        padding: unset;
      }
    }

    @include breakpoint(large) {
      &__link {
        padding-right: 5px;
      }
    }
  }

  @include SearchLinkItem( $borderColor: c('cta'), $oddBgColor: c('grey-100'), $color: c('secondary') ) {
    margin: 0;

    &__link {
      padding-left: 0;

      @include breakpoint(large) {
        padding-left: 15px;
      }
    }
  }
}

.link-item-list__btn {
  @include button($inverted: true) {
    display: block;
    font-size: rem-calc(14);
    margin: 30px 0;

    @include breakpoint(medium) {
      font-size: rem-calc(14);
    }

    @include breakpoint(large) {
      margin: unset;
      padding: 10px 60px;
    }
  }
}

a.search-link-item__link span,
a.link-item-list__link span {
  background: none;

  &:hover {
    background: none;
  }
}

@include Chevron( $className: chevron--search-link, $chevronColor: c('grey-600'), $lineWidth: 2px, $lineLength: 12px, $borderRadius: 0, $height: 20px, $top: 5px ) {
  background-color: transparent;
  right: 0;
  position: absolute;
  @include focus-handler();

  [dir='rtl'] & {
    right: unset;
    left: 0;
  }
}