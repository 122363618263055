@mixin Checkbox($className: 'c-forms') {
  .#{$className} {
    &__checkbox {
      &.c-forms__checkbox--has-focus {
        @include focus-style();
      }
    }

    @include focus-handler();

    &__checkbox-container {
      display: inline-block;

      input[type='checkbox'] {
        opacity: 0;
        width: 0;

        +label:after {
          content: none;
        }

        &:checked+label:after {
          content: '';
        }

        &:checked+label:before {
          background-color: red; //$dark-grey;
          outline: 1px dotted #307f9e;
        }
      }

      label {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          display: inline-block;
          height: 30px;
          width: 30px;
          border: 1px solid black;
          border-radius: 5px;
          top: 3px;
          transition: all 0.2s ease-in-out;
        }

        &:after {
          position: absolute;
          content: '';
          display: inline-block;
          height: 11px;
          width: 17px;
          border-left: 4px solid $white;
          border-bottom: 4px solid $white;
          transform: rotate(-45deg);
          left: 7px;
          top: 10px;
        }
      }

      span {
        margin-left: 40px;
        position: relative;
        top: 7px;
        display: inline-block;
      }
    }
  }
}
