@import '../../platformui/Mobilenav/styles';

@include site-theme(rhb) {
  @include MobileNav( $className: c-metanav, $expandButtonBg: rgba(c('cta'), 0.03), $listBgColor: rgba(c('cta'), 0.1), $listItemColor: c('grey-600') ) {
    @include ExpandButton( $bgColorBtn: rgba($white, 0.07), $plusMinusColor: c('secondary') ) {
      @include focus-handler($offset: -2px);
    }

    a {
      @include focus-handler($offset: -2px);
    }
  }

  @include MobileNavSubLevel( $className: c-metanav, $borderTopColor: rgba(72, 72, 72, 0.2) ) {
    a {
      @include focus-handler($offset: -2px);
    }

    @include SubLevelIndent($className: c-metanav, $textIndent: 30px) {
      // sublevel 2
      @include SubLevelIndent($className: c-metanav, $textIndent: 60px) {
        // sublevel 3
        // prettier-ignore
        @include SubLevelIndent($className: c-metanav, $textIndent: 80px) // sublevel 4
      }
    }
  }
}

@include site-theme(vhb) {
  @include MobileNav( $className: c-metanav, $expandButtonBg: rgba(c('main'), 0.07), $listBgColor: rgba(c('main-dark'), 0.1), $listItemColor: c('main') ) {
    @include ExpandButton( $bgColorBtn: rgba($white, 0.07), $plusMinusColor: c('main') ) {
      @include focus-handler($offset: -2px);
    }

    a {
      @include focus-handler($offset: -2px);
    }
  }

  @include MobileNavSubLevel( $className: c-metanav, $borderTopColor: rgba(72, 72, 72, 0.2) ) {
    a {
      @include focus-handler($offset: -2px);
    }

    @include SubLevelIndent($className: c-metanav, $textIndent: 30px) {
      // sublevel 2
      @include SubLevelIndent($className: c-metanav, $textIndent: 60px) {
        // sublevel 3
        // prettier-ignore
        @include SubLevelIndent($className: c-metanav, $textIndent: 80px) // sublevel 4
      }
    }
  }
}