@import '../Forms/error';
@import '../Scrollbar/styles';

@mixin Survey($bgColor: pink,
  $bgImg: none,
  $border: none,
  $errorIcon: none,
  $errorBorderRadius: 20px,
  $errorBorderColor: #a79391,
  $errorBgColor: white,
  $questionFont: serif,
  $questionColor: pink,
  $titleColor: red,
  $linkColor: red,
  $linkHoverColor: yellow,
  $linkCloseColor: black,
  $submitBtnColor: red,
  $submitBtnHoverColor: yellow,
  $submitBtnHeight: 50px,
  $outline: 2px solid black,
  $formChoiceBorder: 1px solid black,
  $formChoiceBg: white,
  $formChoiceHoverBorder: 1px solid yellow,
  $formChoiceHoverBg: yellow,
  $formChoiceHoverTextColor: white,
  $closeBtnIcon: 'E81E'

) {
  .c-survey {
    display: flex;
    flex-direction: column;
    background: $bgColor;
    background-image: $bgImg;
    background-repeat: no-repeat;
    height: 100%;
    background-size: 100%;
    background-position: bottom 140px center;
    text-align: center;
    padding: 20px 0;
    opacity: 1 !important;
    position: relative;
    border: $border;
    overflow-y: auto;
    overflow-x: hidden;

    &__links {
      padding: rem-calc(26);
    }

    .c-intro-content & {
      background-position: bottom 60px center;
    }

    &-content__item:hover {
      transform: translateY(0);
    }

    &__main {
      flex: 1 0 auto;
      flex-direction: column;
      position: relative;
      display: flex;
    }

    &__header {
      padding-left: 10px;
      padding-right: 10px;

      @include breakpoint(large) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__container {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      padding: 10px;
    }

    @include Error($className: 'c-survey', $color: $black) {
      background-color: $errorBgColor;
      position: absolute;
      width: calc(100% - 20px);
      margin: 5px 10px 10px 0px;
      border: 1px dashed $errorBorderColor;
      box-shadow: none;
      border-radius: $errorBorderRadius;
      padding: 5px 5px 16px 16px;
      z-index: 999;

      &:before,
      &:after {
        content: none;
      }
    }

    &__error {
      display: flex;

      &__icon {
        background-image: $errorIcon;
        background-repeat: no-repeat;
        background-size: 24px;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        float: left;
      }

      div {
        padding-top: 14px;
        font-size: rem-calc(16);
        text-align: left;
      }

      button {
        cursor: pointer;
        height: 25px;
        width: 25px;
        z-index: 2;
        @include icon($closeBtnIcon);
        @include focus-handler();

        @include breakpoint(large) {
          left: calc(100% - 103px);
          top: 68px;
        }

        &:before {
          font-size: 25px;
        }
      }
    }

    &__title {
      font-size: rem-calc(11);
      font-weight: bold;
      letter-spacing: 0.92px;
      text-align: center;
      text-transform: uppercase;
      color: $titleColor;
    }

    &__question {
      line-height: 1;
      font-family: $questionFont, serif;
      font-size: rem-calc(24);
      font-weight: normal;
      color: $questionColor;
    }

    &__link {
      @include focus-handler();
      cursor: pointer;

      &:before {
        font-size: 1.2rem;
        position: absolute;
        left: 0;
        top: 0;
        text-decoration: none;
      }

      text-align: left;
      margin: 10px 20px;
      display: block;
      color: $linkColor;
      line-height: 1.31;
      font-size: rem-calc(16);
      position: relative;
      padding-left: 28px;
      min-height: 24px;

      &:hover {
        color: $linkHoverColor;
      }

      &--close {
        position: absolute;
        top: 7px;
        right: 7px;
        width: rem-calc(30);
        height: rem-calc(30);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;

        &:before {
          position: relative;
          left: unset;
          top: unset;
          content: '\E81E';
          color: $linkCloseColor;
          font-size: rem-calc(26);
          position: relative;
          margin: 0;
          display: inline;
        }

        span {
          height: 0;
          width: 0;
          position: absolute;
          overflow: hidden;
        }
      }
    }

    a {
      text-decoration: none;
    }

    .c-intro-content & {
      @include breakpoint(large) {
        .c-forms {
          flex: 1 0 490px;
        }
      }
    }

    .c-forms {
      flex-grow: 1;
      float: none;
      margin: 0;
      outline: none;

      .EPiServerForms {
        padding-left: 10px;
        padding-right: 6px;

        .Form {
          &__Description {
            font-size: rem-calc(16);
            margin: 0;
            line-height: 1.3;

            padding-left: 26px;
            padding-right: 26px;

            @include breakpoint(large) {
              padding-left: 34px;
              padding-right: 34px;
            }
          }

          &__Element {
            width: 100%;
            float: none;

            &__Caption {
              display: none;
            }

            [type='submit'],
            &.FormSubmitButton {
              @extend .c-forms__button;
              display: inline-block;
              float: none;
              text-align: center;
              width: 200px;
              height: $submitBtnHeight;
              line-height: 1;
              background-color: $submitBtnColor;
              margin: 10px;

              &:hover {
                background-color: $submitBtnHoverColor;
              }

              &.FormImageSubmitButton {
                background: transparent;
                padding: 0;

                &:hover {
                  box-shadow: none;
                }
              }
            }

            &.FormChoice {
              margin-bottom: 0;
              text-align: center;

              label {
                cursor: pointer;
                display: block;
                padding: 10px 20px;
                transition: all 0.3s ease;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
                border-radius: 20px;
                height: 100%;
                border-radius: 20px;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
                border: $formChoiceBorder;
                background-color: $formChoiceBg;
                font-size: rem-calc(18);
                line-height: 1;
                margin: 10px 4px 10px 0;

                @include breakpoint(large) {
                  text-align: left;
                }

                @include focus-handler($offset: 8px);

                &.checked,
                &:hover {
                  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
                  border: $formChoiceHoverBorder;
                  background-color: $formChoiceHoverBg;
                  color: $formChoiceHoverTextColor;
                }
              }

              input[type='radio'] {
                &+span {
                  &:after {
                    content: none;
                  }
                }

                &:checked+span {
                  &:before {
                    content: none;
                  }

                  &:after {
                    content: none;
                  }
                }
              }

              input[type='checkbox'] {
                &+span {
                  &:after {
                    content: none;
                  }
                }

                &:checked+span {
                  &:before {
                    content: none;
                  }

                  &:after {
                    content: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .u-article-main .c-survey-content__item {
    margin-bottom: 50px;

    @include breakpoint(large) {
      margin-bottom: 100px;
    }
  }

  .u-article-main .c-survey {
    @include breakpoint(large) {
      &__header {
        padding-left: 50px;
        padding-right: 50px;
      }

      &__result-list {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 26px;
        padding-right: 26px;
        padding-bottom: 12px;
      }

      &__link {
        @include focus-handler();
        margin: 10px 50px;
      }

      &__error {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 55px);
        border-radius: 35px;
        padding: 10px;
        right: 15px;

        button {
          height: 25px;
          width: 25px;
          margin-right: 5px;
          align-self: center;
          left: unset;
          top: unset;
        }

        &:before {
          font-size: 25px;
        }

        div {
          display: flex;
          align-items: center;
          padding: 0;
        }

        &__icon {
          background-size: 50px;
          height: 50px;
          width: 50px;
        }
      }

      .c-forms .EPiServerForms .Form__Element.FormChoice label {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  @content;
}
