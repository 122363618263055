@import '../../platformui/Localnav/styles';

/*RHB/VHB*/
@include site-theme(rhb) {
  @include Localnav( $borderColor: rgba(c('grey-600'), 0.4), $headingPadding: 0 0 20px, $itemColor: c('cta'), $itemPadding: 14px 42px 15px 10px, $borderMarkerColor: c('main'), $hasChildrenIconColor: rgba(c('grey-600'), 0.6), $selectedItemColor: c('grey-600') ) {
    margin-bottom: 60px;

    &__heading {
      a {
        @include focus-handler();
        @include base-font-family();
        @include large-text(c('grey-550'));
      }
    }

    &__list {
      @include base-font-family();
      @include medium-text();

      &__item {
        a {
          @include focus-handler();
        }

        &:first-child {
          ul > & {
            border-top: solid 5px rgba(c('grey-600'), 0.5);
          }

          ul ul > & {
            border-top: solid 1px rgba(c('grey-600'), 0.4);
          }
        }
      }
    }
  }
}

/*RHB/VHB*/
@include site-theme(vhb) {
  @include Localnav( $borderColor: rgba(c('grey-600'), 0.4), $headingPadding: 0 0 20px, $itemPadding: 14px 42px 15px 10px, $itemColor: c('main-dark'), $borderMarkerColor: c('main'), $hasChildrenIconColor: rgba(c('grey-600'), 0.6), $selectedItemColor: c('grey-600') ) {
    &__heading {
      a {
        @include focus-handler();
        @include base-font-family();
        @include large-text(c('grey-550'));
      }
    }

    &__list {
      @include base-font-family();
      @include medium-text();

      &__item {
        a {
          @include focus-handler();
        }

        &:first-child {
          ul > & {
            border-top: solid 5px rgba(c('grey-600'), 0.5);
          }

          ul ul > & {
            border-top: solid 1px rgba(c('grey-600'), 0.4);
          }
        }
      }
    }
  }
}