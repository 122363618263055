@import '../../platformui/HighLight/styles';

@include HighLight( $importantInfoIcon: '../../framework/images/rhb-info-icon.svg', $importantIcon: '../../framework/images/vhb-important-icon.svg', $routinesIcon: '../../framework/images/vhb-routines-icon.svg', $recommendationsIcon: '../../framework/images/vhb-recommendations-icon.svg', $localInstructionsIcon: '../../framework/images/vhb-localinstr-icon.svg', $margin: ( 0 0 30px, 0 0 30px 0, ), $padding: ( 15px 20px 0, 15px 20px 0, ) ) {
  font-size: rem-calc(14);

  p,
  ol,
  ul {
    font-size: inherit;
  }

  ul {
    padding-left: rem-calc(20);
  }

  ol {
    padding-left: rem-calc(18);

    @include breakpoint(medium) {
      padding-left: rem-calc(28);

      li {
        padding-left: rem-calc(8);
      }
    }
  }

  & > [data-epi-property-name] {
    margin-top: 30px;
  }
  & > * {
    &:last-child {
      margin-bottom: rem-calc(14);
    }
  }

  &--frame {
    border-radius: 10px;

    @include site-theme(rhb) {
      border: solid 1px c('grey-600');
    }

    @include site-theme(vhb) {
      border: solid 1px c('grey-550');
    }
  }

  &--color1 {
    background-color: rgba(c('grey-600'), 0.1);
    border-radius: 10px;

    @include site-theme(rhb) {
      border: solid 1px c('grey-600');
    }

    @include site-theme(vhb) {
      border: solid 1px c('grey-550');
    }

    @media print {
      background-color: transparent;
    }
  }

  @include site-theme(rhb) {
    &--color2 {
      background-color: rgba(c('secondary'), 0.1);
      border: solid 1px c('secondary');
      border-radius: 10px;

      @media print {
        background-color: transparent;
      }
    }
  }

  @include site-theme(vhb) {
    &--color2 {
      background-color: c('warning-light');
      border: solid 1px c('main');
      border-radius: 10px;

      @media print {
        background-color: transparent;
      }
    }
  }

  &--localinstructions,
  &--recommendations,
  &--routines,
  &--important,
  &--importantinfo {
    @media print {
      background-position: 10px 8px;
    }

    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 10px 11px;
    padding-left: 50px;
  }

  @media print {
    border-color: $black;
  }
}