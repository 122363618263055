.u-error {
  body {
    background: #eee;
    min-height: 0;
  }
}

.c-error {
  height: 100vh;
  text-align: center;
  width: 100%;

  @include breakpoint(medium) {
    padding-bottom: 154px;
  }

  .preamble {
    color: c('grey-500');
  }

  &__container {
    display: flex;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    height: 100vh;

    &__inner {
      margin: auto;
      padding-bottom: 200px;
    }
  }

  &__heading {
    color: c('grey-600');
  }

  &__footer {
    background: $white;
    bottom: 0;
    height: 200px;
    position: fixed;
    text-align: center;
    width: 100%;

    @include breakpoint(medium) {
      bottom: 0;
      height: 154px;
      overflow: hidden;
    }

    .c-header__logo__svg-small {
      display: none;
    }

    &__rhb {
      display: inline-block;
      width: 100%;

      svg {
        height: 50px;
        margin-top: 30px;
        width: 200px;
      }

      @include breakpoint(medium) {
        height: 154px;
        margin: 0;
        width: 247px;

        svg {
          float: left;
          margin-top: 60px;
        }
      }
    }

    &__vhb {
      display: inline-block;
      height: 70px;
      margin: 0;
      width: 100%;

      svg {
        height: 65px;
        margin-top: 20px;
        width: 110px;
      }

      @include breakpoint(medium) {
        height: 154px;
        width: 110px;

        svg {
          float: left;
          margin-top: 40px;
        }
      }
    }
  }
}
