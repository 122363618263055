.c-facets {
  border-bottom: solid 1px c('grey-600');
  margin-bottom: 40px;
  padding-bottom: 0;
  text-align: center;

  &__inner {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(746);
  }

  &__items {
    font-size: 0;
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
      margin-bottom: rem-calc(30);

      &:last-child {
        margin-bottom: rem-calc(25);
      }
    }
  }

  &__item {
    display: inline-block;
    margin-right: rem-calc(5);
    overflow: hidden;
    position: relative;
    border: solid 1px c('grey-600');
    border-radius: 10px;
    background-color: transparent;
    color: c('grey-600');
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 10px;
    padding: 8px 16px 9px;

    @include breakpoint(medium) {
      margin-right: 10px;
    }

    @include breakpoint(large) {
    }

    &--selected,
    &:hover,
    &:focus {
      border-color: $black;
      background-color: $black;
      color: $white;
      text-decoration: none;
      outline: none;
    }

    &--selected {
      background-color: c('grey-600');
      color: $white;
    }

    &__checkbox {
      @include focus-handler();
      position: absolute;
      left: -9999px;
    }

    &__label {
      display: block;
    }
  }
}
