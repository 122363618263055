.c-news-push {
  @include breakpoint(large) {
    display: flex;
    flex-wrap: wrap;
  }
  margin-top: 20px;

  .c-teaser {
    margin-bottom: 20px;
    @include focus-handler();

    @include site-theme(rhb) {
      background: rgba(c('secondary'), 0.1);
    }

    &-outer {
      padding-left: 0;
    }

    &__heading {
      @include site-theme(rhb) {
        border-color: rgba(c('secondary'), 0.3);

        &__link {
          color: c('cta');

          span {
            color: c('cta');
          }
        }
      }

      @include site-theme(vhb) {
        border-color: rgba(c('grey-600'), 0.4);

        &__link {
          color: c('secondary');

          span {
            color: c('secondary');
          }
        }
      }
    }

    &--eq-height {
      @include site-theme(rhb) {
        border-color: c('light-bg');
      }
      @include breakpoint(large) {
        border: none;
      }
    }
  }

  //EPi On page edit
  & > .epi-editContainer {
    display: flex;
    margin-top: 20px;
    width: 100%;

    & > div {
      height: calc(100% - 35px);
    }

    .columns {
      //height: 100%;
      width: 100%;
    }
  }
}
