@import '../../platformui/ScrollToTop/styles';

@include site-theme(rhb) {
  @include ScrollToTop( $bgImage: url('../../framework/images/rhb/to-top.svg'), $focusColor: $black ) {
    @include breakpoint(large) {
      bottom: 15px;
    }
  }
}

@include site-theme(vhb) {
  @include ScrollToTop( $bgImage: url('../../framework/images/vhb/to-top.svg'), $focusColor: $black ) {
    @include breakpoint(large) {
      bottom: 15px;
    }
  }
}