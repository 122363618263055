@mixin ContentHistory(
  $oddListItemBg: transparent,
  $linkColor: orange,
  $linkHoverColor: salmon,
  $panelHeight: 55px,
  $headingColor: green,
  $headingBorderColor: black,
  $startpageBg: $white,
  $infoPanelBorderColor: black
) {
  .content-history {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__heading {
      color: $headingColor;
      border-bottom: 1px solid rgba($headingBorderColor, 0.4);
      padding-bottom: 15px;
      margin-bottom: 15px;

      &__icon {
        @include icon('\e808');

        &:before {
          position: relative;
          padding-right: 25px;
        }
      }
    }

    &__info-panel {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 20px;

      @include breakpoint(large) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $infoPanelBorderColor;
        border-bottom: 1px solid $infoPanelBorderColor;
        height: $panelHeight;
        margin-bottom: 40px;
      }

      p {
        color: $black;
        margin-bottom: 0;
        padding-bottom: 20px;
        display: inline-block;
        border-bottom: 1px solid rgba($black, 0.2);

        @include breakpoint(large) {
          padding: 0 20px 0 0;
          border-bottom: none;
        }
      }
    }

    &__facets {
      transition: all 0.3s ease-in-out;

      &__panel {
        height: 0;
        overflow: hidden;
        visibility: hidden;
        width: 100%;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        .facets-shown & {
          opacity: 1;
          overflow: visible;
          visibility: visible;
        }
      }
    }

    &__filter-btn {
      width: 100%;
      height: $panelHeight;
      text-align: left;
      font-weight: bold;
      position: relative;
      border-bottom: 1px solid rgba($black, 0.2);
      transition: background-color 0.2s ease-in-out;

      @include breakpoint(large) {
        width: 155px;
        padding-left: 20px;
        border-bottom: none;
        border-left: 1px solid $infoPanelBorderColor;
      }

      @include breakpoint(1120px) {
        width: 200px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 22px;
        left: calc(100% - 28px);
        height: 11px;
        width: 2px;
        background: $black;
        transform: rotate(135deg);
        transition: transform 0.2s ease-in;
      }

      &:after {
        content: '';
        position: absolute;
        top: 22px;
        left: calc(100% - 21px);
        height: 11px;
        width: 2px;
        background: $black;
        transform: rotate(-135deg);
        transition: transform 0.2s ease-in, left 0.2s ease-in;
      }

      &.facets-open {
        @include breakpoint(large) {
          background-color: rgba($black, 0.03);
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    &__list {
      //list-style: none;
      margin: 0;
      padding: 0;
      margin-bottom: 40px;
      font-size: rem-calc(14);
      line-height: 1.43;
      overflow: hidden;
      position: relative;

      .content-history--start-page & {
        margin-bottom: 0;

        @include breakpoint(large) {
          margin-bottom: 10px;
        }
      }

      &__footer {
        text-align: center;
        margin-bottom: 40px;
      }

      @media print {
        padding: 0 !important;
      }
    }

    &__item {
      background-color: transparent;
      color: black;
      list-style: none;
      margin: 0 auto;
      min-height: 80px;
      padding: 17px 20px;

      &:nth-child(odd) {
        background-color: $oddListItemBg;
        border-radius: 5px;
      }

      a {
        color: $linkColor;
        display: inline;
        font-size: rem-calc(18);
        font-weight: 900;
        line-height: 1.22;
        padding-top: 1px;

        @media print {
          padding-left: 20px;
        }
      }
    }

    &__simplified-item {
      a {
        color: $linkColor;
        font-size: rem-calc(16);
        line-height: 1.5;

        &:hover {
          color: $linkHoverColor;
        }
      }

      span {
        font-size: rem-calc(16);
        line-height: 1.5;
        color: $infoPanelBorderColor;
      }
    }

    &--start-page {
      padding: 20px 20px 30px;
      border-radius: 10px;
      background-color: $startpageBg;
      transition: transform 0.15s ease-out, box-shadow 0.15s ease-out;

      @include breakpoint(medium) {
        padding: 20px;

        &:hover,
        &:focus {
          box-shadow: 0 4px 8px 0 rgba(26, 26, 26, 0.2);
          transform: translateY(-10px);
        }
      }

      @include breakpoint(large) {
        height: 407px;
      }
    }
  }
  @content;
}
