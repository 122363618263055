@mixin AdjustableLinkBlock(
  $backgroundColor: transparent,
  $backgroundImage: none,
  $contentPadding: 10px 0 30px,
  $useIcon: true,
  $iconBackgroundColor: transparent,
  $minHeight: 200px,
  $textFontStyle: normal,
  $textFontSize: inherit
) {
  .c-navigationpush {
    min-height: $minHeight;

    @if ($backgroundImage != none) {
      &__bg {
        bottom: auto;
        padding: 0;
        position: absolute;
        top: 0;
        margin-left: -70px;
        margin-right: -70px;
        min-height: 350px;
        width: 100%;
        z-index: 1;

        @include breakpoint(xlarge) {
          margin-left: -90px;
          margin-right: -130px;
        }

        &__inner {
          background-color: $backgroundColor;
          background-image: $backgroundImage;
          background-repeat: no-repeat;
          min-height: 520px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-position: bottom left;
          background-size: auto 125%;
          z-index: 2;

          @include breakpoint(xlarge) {
            min-height: 700px;
          }
        }
      }
    }

    &__content {
      padding: $contentPadding;
      position: relative;
      z-index: 2;
    }

    @if ($useIcon == true) {
      &__icon {
        background: $iconBackgroundColor;
        border-radius: 50%;
        display: block;
        height: 34px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
        position: relative;
        width: 34px;
      }
    }
  }
  @content;
}
