@mixin Input(
  $className: 'c-forms',
  $bg: rgba(#ff00ff, 0.2),
  $border: solid 1px transparent,
  $borderRadius: 25px,
  $color: black,
  $focusBorder: solid 1px rgba(#ff00ff, 0.4),
  $focusShadow: inset 2px 6px 6px 0 rgba(#ff00ff, 0.15),
  $disabledBg: rgba(#000, 0.03),
  $disabledBorder: solid 1px rgba(#000, 0.2)
) {
  .#{$className} {
    &__input {
      position: relative;

      input {
        border: $border;
        border-radius: $borderRadius;
        color: $color;
        float: left;
        height: 50px;
        margin-bottom: 20px;
        padding: 0 50px 0 20px;
        width: 100%;
        background-color: $bg;
        @include focus-handler();
        &:focus {
          border: $focusBorder;
          box-shadow: $focusShadow;
        }
      }

      &--is-error {
        input {
          @extend %--is-error;
        }
      }

      input {
        &[disabled] {
          background-color: $disabledBg;
          border: $disabledBorder;
        }
      }

      @content;
    }
  }
}
