@import '../../platformui/Spinner/styles';

@include spinner($margin: 0) {
  &--search {
    margin: -15px auto 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    span {
      @include site-theme(rhb) {
        background-color: c('secondary');
      }

      @include site-theme(vhb) {
        background-color: c('main');
      }

      width: 15px;
      height: 15px;
    }
  }

  &--reading-aid {
    margin: 3px auto;

    span {
      background-color: $white;
    }
  }

  &--content-history {
    position: absolute;

    span {
      @include site-theme(rhb) {
        background-color: c('secondary');
      }

      @include site-theme(vhb) {
        background-color: c('main');
      }
    }
  }
}