@import '../../platformui/Section/styles';

@include Section( $alternatingBgColor: rgba-to-rgb(rgba(c('secondary-alt'), 0.08)), $greyBgColor: rgba-to-rgb(rgba(c('grey-600'), 0.08)), $headingMaxWidth: 100% ) {
  &__heading {
    font-size: rem-calc(24);
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    line-height: 1.18;
    color: c('grey-550');
    font-weight: normal;
    padding-bottom: 30px;
    flex-grow: 1;

    .c-section--navigation & {
      border-bottom: none;
      padding-bottom: 0;
    }

    @include breakpoint(medium) {
      text-align: center;
    }

    @include breakpoint(large) {
      font-size: rem-calc(28);
      line-height: 1.18;
      padding-bottom: 40px;
    }
  }

  &--primary-intro {
    .c-section__inner {
      padding-bottom: 60px;

      .u-float-top-4 {
        @include breakpoint(large) {
          position: absolute;
          right: 0;
          margin-top: 0;
      
          .content-history--start-page:after {
            bottom: 10px;
          }
        }
      }
    }

    .c-entry {
      @include focus-handler();
    }

    @include site-theme(vhb) {
      background-color: rgba(c('grey-600'), 0.1);
    }
  }
}