@import '../../platformui/Teaser/styles.scss';
@import './list-view-toggler.scss';

@include Teaser(
  c('main'),
  //Header color
  c('grey-600'),
  //Vignette color
  c('main'),
  //Heading Icon color
  0 0 19px 0,
  //Heading padding
  0 0 13px 0,
  //Heading margin
  none,
  //Box shadow
  $componentBorderRadius: (10px, 10px, 10px),
  $componentPageListBorderBottom: 10px solid $white,
  $componentPageListBorderBottomHover: 10px solid $white,
  $headingFontWeight: 900
) {
  text-decoration: none;
  overflow: hidden;

  &--eq-height {
    height: 100%;
  }

  &-outer {
    &:last-child:not(:first-child) {
      float: left;
    }
  }

  &__heading {
    @include base-font-family();
    @include xmedium-text();
    border-bottom: solid 1px rgba(c('main'), 0.4);

    @include site-theme(rhb) {
      &--no-text {
        border-bottom: none;
      }
    }
    &:before {
      z-index: 0;
    }
  }

  &__text {
    @include medium-text(c('grey-600'));
    p {
      display: -webkit-inline-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 75px;
    }
  }

  .c-linklist__link {
    @include site-theme(rhb) {
      color: c('main');
    }

    @include site-theme(vhb) {
      color: c('main-dark');
    }
  }
}

.c-section {
  &__inner {
    .c-teaser {
      @include focus-handler();
    }
  }
}
