.Form {
  &__MainBody {
    text-align: center;
  }

  &__Title {
    border-top: solid 1px rgba($black, 0.2);
    margin-top: 40px;
    padding-top: 40px;

    color: c('main');
  }

  &__Description {
    .EPiServerForms & {
      margin-bottom: 25px;
    }
  }

  &__Element {
    float: left;
    text-align: left;
    width: 100%;

    .EPiServerForms & {
      margin-bottom: 0;
    }

    &.FormStep {
      text-align: center;
    }

    [type='submit'],
    &.FormSubmitButton {
      @extend %button;
      display: inline-block;
      margin-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
      float: none;
      text-align: center;
      width: auto;
      @include focus-handler();

      &:disabled {
        opacity: 1;
        background-color: c('disabled');
        color: c('grey-500');
        border-color: c('grey-500');
      }

      &.FormImageSubmitButton {
        background: transparent;
        padding: 0;
        @include focus-handler();

        &:hover {
          box-shadow: none;
        }
      }
    }

    [type='reset'],
    &.FormResetButton {
      @extend %button;
      @extend %button--secondary;
      @include focus-handler();

      margin-left: 10px;
      margin-right: 10px;

      &.FormImageSubmitButton {
        background: transparent;
        padding: 0;
        @include focus-handler();

        &:hover {
          box-shadow: none;
        }
      }

      display: inline-block;
      float: none;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: center;
      width: auto;
    }

    /* PARAGRAPH TEXT */
    &.FormParagraphText {
      margin-top: 5px;
      h2,
      h3 {
        margin-top: 10px;
      }
    }

    &.ValidationRequired {
      .Form__Element__Caption {
        &:after {
          content: '*';
          color: c('error');
          display: inline-block;
          margin: 0 2px;
        }
      }
    }

    &.FormChoice {
      margin-bottom: 20px;

      label {
        color: c('grey-600');
        display: block;
        overflow: hidden;
        padding: 6px 0 6px 30px;
        position: relative;
        @include focus-within-handler();
      }

      input[type='radio'] {
        position: absolute;
        left: -30px;
        top: 0;

        & + span {
          &:after {
            @include site-theme(rhb) {
              background: rgba(c('secondary'), 0.2);
              border: solid 1px c('secondary');
            }

            @include site-theme(vhb) {
              background: rgba(c('main-light'), 0.1);
              border: solid 1px c('main');
            }

            border-radius: 100px;
            content: '';
            cursor: pointer;
            display: inline-block;
            height: 20px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 20px;
          }
        }

        &:checked + span {
          &:before {
            @include site-theme(rhb) {
              background: c('cta');
            }

            @include site-theme(vhb) {
              background: c('main-dark');
            }

            border-radius: 100px;
            content: '';
            display: block;
            height: 14px;
            width: 14px;
            left: 3px;
            position: absolute;
            top: 11px;
            z-index: 2;
          }
        }
      }

      input[type='checkbox'] {
        position: absolute;
        left: -30px;
        top: 0;

        & + span {
          &:after {
            @include site-theme(rhb) {
              background: rgba(c('secondary'), 0.2);
              border: solid 1px c('secondary');
            }

            @include site-theme(vhb) {
              background: rgba(c('main-light'), 0.1);
              border: solid 1px c('main');
            }

            border-radius: 4px;
            content: '';
            cursor: pointer;
            display: inline-block;
            height: 20px;
            left: 0;
            position: absolute;
            top: 8px;
            width: 20px;
          }
        }

        &:checked + span {
          @include icon('e837');

          &:before {
            @include site-theme(rhb) {
              color: c('cta');
            }

            @include site-theme(vhb) {
              color: c('main-dark');
            }

            font-size: 12px;
            left: 3px;
            position: absolute;
            top: 12px;
            z-index: 2;
          }
        }
      }
    }
  }

  /* TEXTFIELD */
  &Textbox {
    @extend .c-forms__input;

    &.ValidationFail {
      @extend .c-forms__input--is-error;
    }
  }

  /* TEXTAREA */
  &Textbox--Textarea {
    &.ValidationFail > .c-forms__textarea {
      @extend .c-forms__textarea--is-error;
    }
  }

  /* SELECTBOX */
  &Selection {
    &.ValidationFail {
      .c-forms__select {
        @extend .c-forms__select--is-error;
      }
    }
  }

  /* LABELS */
  &__Element__Caption {
    @extend .c-forms__label;
  }

  /* ERROR MESSAGES */
  &__Element__ValidationError {
    .FormChoice & {
      margin-top: 12px;
    }
  }
}
.ValidationFail > .Form__Element__ValidationError {
  @extend .c-forms__error;
}

.EPiServerForms .Form__Status .Form__Status__Message {
  @extend .c-self-test__result;
  @extend .u-content-editable;

  padding: 20px 20px 5px;

  p,
  ul,
  ol {
    font-size: rem-calc(14);
  }
  ul {
    li {
      &:before {
        margin-right: 5px;
      }
    }
  }

  ol {
    li {
      padding-left: 6px;
    }
  }

  a {
    background-position: 0 18px;
  }
}

// Specific styling for subscription blocks
.EPiServerForms.subscription {
  .Form__Title {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: c('grey-550');
  }

  .Form__Description {
    color: c('grey-600');
  }

  .Form__MainBody > section {
    button {
      margin: 0;
      font-size: 20px;
      padding: 13px 20px;

      @include breakpoint(large) {
        margin: 20px 10px;
      }
    }
  }
}
