.c-chapter-container {
  &--is-nav {
    h2 {
      display: none;

      @include breakpoint(large) {
        display: block;
      }
    }
  }
}
