@mixin Textarea(
  $className: 'c-forms',
  $bg: magenta,
  $border: solid 1px transparent,
  $borderRadius: 25px,
  $color: black,
  $focusBorder: solid 1px magenta,
  $focusShadow: inset 2px 6px 6px 0 magenta,
  $disabledBg: rgba(0, 0, 0, 0.03),
  $disabledBorder: solid 1px rgba(0, 0, 0, 0.2),
  $charsColor: rgba(0, 0, 0, 0.5),
  $scrollBarBg: magenta,
  $scrollBarWidth: 28px,
  $scrollBarShadowWidth: 1px,
  $margin: 0 0 20px 0,
  $padding: 25px 20px
) {
  .#{$className} {
    &__textarea {
      background: $bg;
      border: $border;
      border-radius: $borderRadius;
      float: left;
      height: 170px;
      margin: $margin;
      padding: $padding;
      width: 100%;

      textarea {
        background: transparent;
        border: none;
        color: $color;
        height: 100%;
        resize: none;
        width: 100%;

        &:focus {
          outline: none;
        }

        &::-webkit-scrollbar {
          width: $scrollBarWidth; /* Total width including `border-width` of scrollbar thumb */
          height: 0;
        }
        &::-webkit-scrollbar-thumb {
          height: 14px;
          border: 11px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
          background-clip: padding-box;
          border-radius: 20px;
          background-color: $scrollBarBg;
          box-shadow: inset 0 0 0 $scrollBarShadowWidth $scrollBarBg;
        }
        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
          display: none;
        }
        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }
      }

      &--has-focus {
        @include focus-style();
        border: $focusBorder;
        box-shadow: $focusShadow;
      }

      &--disabled {
        background-color: $disabledBg;
        border: $disabledBorder;
      }

      &--is-error {
        @extend %--is-error;

        &.c-forms__textarea--has-focus {
          @extend %--is-error--has-focus;
        }
      }

      &__chars {
        display: inline-block;
        float: right;
        font-weight: 300;
        font-style: italic;
        margin-top: 4px;
        padding: 0 10px;

        &--disabled {
          color: $charsColor;
        }
      }

      @content;
    }
  }
}
