@import '../../platformui/SelfTest/styles';
@include SelfTest(
  $fontColor: c('grey-600'),
  $okIconUrl: url('../../framework/images/correct.svg'),
  $notOkIconUrl: url('../../framework/images/error.svg'),
  $errorColor: c('error'),
  $errorBgColor: rgba(c('error'), 0.05),
  $validBgColor: rgba(67, 146, 53, 0.1),
  $checkedFontelloIcon: 'e837'
) {
  .c-self-test {
    &__button {
      color: c('white');
      @include site-theme(rhb) {
        background-color: c('cta');

        &:hover {
          background-color: darken(c('cta'), 5%);
          color: c('white');
        }
      }
      @include site-theme(vhb) {
        background-color: c('main');
      }

      &--secondary {
        @include site-theme(rhb) {
          color: c('cta');
          background-color: c('white');
          border: 1px solid c('cta');

          &:hover {
            background-color: darken(c('cta'), 5%);
          }
        }
        @include site-theme(vhb) {
          color: c('main');
          background-color: c('white');
          border: 1px solid c('main');

          &:hover {
            color: c('white');
            background-color: c('main-dark');
          }
        }
      }
    }
    &__option-text {
      &:before {
        @include site-theme(rhb) {
          color: c('cta');
        }

        @include site-theme(vhb) {
          color: c('main');
        }
      }

      &:after {
        @include site-theme(rhb) {
          background: rgba(c('secondary'), 0.2);
          border: solid 1px c('secondary');
        }

        @include site-theme(vhb) {
          background: rgba(c('main'), 0.2);
          border: solid 1px c('main');
        }
      }
    }

    &__result {
      @include site-theme(rhb) {
        background: c('textfield-bg');
        border: solid 1px c('secondary');
      }

      @include site-theme(vhb) {
        background: rgba(c('main'), 0.1);
        border: solid 1px c('main');
      }
    }

    &__button {
      @extend %button;
      margin: 0 10px 40px 0;
      color: c('white');
      @include focus-handler();
      @include site-theme(rhb) {
        background-color: c('cta');

        &:hover {
          background-color: darken(c('cta'), 5%);
          color: c('white');
        }
      }

      &--secondary {
        @extend %button--secondary;
        @include site-theme(rhb) {
          color: c('cta');
          background-color: c('white');

          &:hover {
            background-color: darken(c('cta'), 5%);
          }
        }
        @include site-theme(vhb) {
          color: c('main');
          background-color: c('white');

          &:hover {
            color: c('white');
            background-color: c('main-dark');
          }
        }
      }
    }
  }
}
